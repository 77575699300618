.logoContainerDesktop, .logoContainerMobile{
  display: flex;
  align-items: center;
  margin-top: -0.5em;
  margin-bottom: 2em;
  cursor: pointer;
  width: fit-content;
  margin-left: 0.5em;
}.logoContainerMobile{
  margin-top: -3.5em;
}

.logo{
  margin-top: 1em;
  width: 110px;
  height: 60px;
}

.logoName{
  font-family: 'Nunito';
  font-weight: 700;
  font-size: 1.2em;
  color: rgb(44, 44, 44);
}

.contentContainerDesktop, .contentContainerMobile{
display: flex;
flex-direction: column;
width: 100%;
max-width: 78.5em;
}

.formContainerDesktop, .formContainerMobile{
  max-width: 27em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
  background-color: white;
  box-shadow: 0px 0px 30px rgb(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 1em;
}.formContainerMobile{
  margin:1em;
}

.textTitle, .categoryTextTitle{
  font-family: 'Nunito';
  font-weight: 700;
  color: rgb(44, 44, 44);
  margin-top: 1em;
  margin-bottom:0.5em;
  margin-left:0.4em;
}.categoryTextTitle{
  font-size: 1.5em;
  margin-top: 0.5em;
}.simpleTextDesktop, .simpleTextMobile, .successMessage{
  margin-top: -2em;
  margin-bottom: 1.5em;
  font-size: 1em;
  font-family: 'Nunito';
  font-weight: 300;
  color: rgb(100, 100, 100);
}.simpleTextMobile{
  font-size: 0.9em;
}.successMessage{
  font-size: 1.4em;
  font-weight: 600;
  color: rgb(44, 44, 44);
  width: 20em;
  max-width: 25em;
}

.successContainer{
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
}

.contactUsText{
  margin:0;
  margin-top: -0.5em;
  margin-bottom:1em;
  font-size: 3em;
  font-family: 'Nunito';
  font-weight: 500;
}

.test{
  padding: 1em;
}

.nameContainer{
  display: flex;
  justify-content: space-between;
  max-width: 100%;
}

.titleContainer, .nameInputContainer, .descriptionContainer{
  padding: 0.5em 1em;
  border: 2px solid rgb(220, 220, 220);
  border-radius: 8px;
  max-width: 25em;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(245, 245, 245);
}.nameInputContainer{
  width: calc(100% - 4em);
  margin:0;
}.descriptionContainer{
  padding: 0em 1em;
}
.titleContact, .descriptionContact{
  width: 100%;
  padding: 0.5em 0;
  user-select: none;
  outline: none;
  border: none;
  border-radius: 8px;
  font-size: 1em;
  font-family: 'Nunito';
  font-weight: 400;
  background-color: rgb(245, 245, 245);

}

.descriptionContact{
  height: 10em;
  padding: 0.5em 0;
  background-color: rgb(245, 245, 245);
}

.submitButton{
  padding: 0.8em 1.8em;
  width: fit-content;
  border-radius: 4px;
  font-size: 1em;
  font-family: 'Nunito';
  font-weight: 500;
  cursor: pointer;
}

.submitInactive{
  color: rgb(128, 128, 128);
  background-color: rgb(230, 230, 230);
}.submitActive{
  color: rgb(255, 255, 255);
  background-color: rgb(50, 85, 160);
}

@media(min-width: 1050px){
  .test{
    position: fixed;
    top: 12em;
    max-width: 35em;
  }

  .formContainerDesktop{
    /* margin-left: auto; */
    margin-right: 2em;
  }

}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}