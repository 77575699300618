.background{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9997;
}.fadeIn{
  animation: fadeIn 0.3s ease-out;
  animation-fill-mode: forwards;
  background-color: rgba(0, 0, 0, 0.8);
}.fadeOut{
  animation: fadeOut 0.3s ease-out;
  animation-fill-mode: forwards;
  background-color: rgba(0, 0, 0, 0);
}

@keyframes fadeIn {
  from {
      background-color: rgba(0, 0, 0, 0);
}
to {
  background-color: rgba(0, 0, 0, 0.8);
}
}@keyframes fadeOut {
  from {
      background-color: rgba(0, 0, 0, 0.8);
}
to {
  background-color: rgba(0, 0, 0, 0);
}
}
.createContainer{
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}.textTitle, .textDescription, .textTitleInput, .textNote, .textNoteDesktop, .priceSymbol, .hoursStyle, .priceNote{
  font-family: 'Nunito';
  font-size: 1.9em;
  font-weight: 700;
  width: 100%;
  margin: 0em;
  color: rgb(44,44,44);
}.textTitle{
  margin-top: 1.5em;
}.textDescription{
  margin-top: 0.5em;
  margin-left: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.1em;
  font-weight: 600;
  color: rgb(90, 95, 103);
}.textTitleInput{
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 0.5em;
}.textNote{
  position: absolute;
  bottom: 0em;
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 0em;
}.textNoteDesktop{
  margin-top: 1em;
  font-size: 1em;
  font-weight: 400;
}.priceSymbol{
  font-size: 1em;
  width: auto;
  margin-right: 0em;
}.hoursStyle{
  font-size: 1em;
  width: auto;
  font-weight: 650;
}.priceNote{
  margin-top: 1em;
  font-size: 1em;
  font-weight: 400;}

.mobileContainer{
  position: fixed;
  z-index: 9997;
  bottom: 0;
  top: 0;
  width: 100%;
  /* padding: 1em; */
  background-color: rgb(255, 255, 255);
  backdrop-filter: blur(15px);
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
}.slideUp{
  animation: slideUp 0.3s ease-out;
  animation-fill-mode: forwards;
}.slideDown{
  animation: slideDown 0.3s ease-out;
  animation-fill-mode: forwards;
  right: -100%;
}@keyframes slideUp {
  from {
    right: -100%; /* Start position, hidden below the screen */
}
to {
  right: 0%; /* End position, slide up to the bottom of the screen */
}
}@keyframes slideDown {
  from {
    right: 0%;
}
to {
  right: -100%;
}
}

.exitButton{
  margin-top: 1em;
  margin-left: 1em;
  width: 20px;
  height: 20px;
  color: white;
  cursor: pointer;
  transform: rotate(180deg);
}

.stepStyle{
  font-size: 1em;;
  font-family: 'Nunito';
  font-weight: 750;
  color: rgb( 44, 44, 44);
}

.titleContainer{
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.titleTextarea{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 35em;
  height: fit-content;
  margin-top: 0em;
  padding: 0.5em;
  text-align: left;
  font-size: 1em;
  background-color: rgb(245, 245, 245);
  border: 2px solid rgb(0, 0, 0);
  border-radius: 12px;
  outline: none;
  color: rgb(44, 44, 44);
  font-size: 1em;
  font-weight: "Nunito";
  font-weight: 450;
  margin-left: auto;
  margin-right: auto;
}

.container input, textarea, .locationInput, .typeInput,  .date, .time, .hours, .price{
    width: 95%;
    height: 1em;
    padding: 10px 10px;
    border:none;
    border: 1px solid rgb(211, 211, 211);
    background-color: whitesmoke;
    color: rgb(44, 44, 44);
    font-size: 1em;
    border-radius: 4px;
    outline: none;
    resize: none;
}.locationInput{
  width: 20em;
  margin: 1em;
  border: 1px solid rgb(211, 211, 211);
}.hours{
  border: none;
  border-radius: 12px;
}.price{
  border: none;
}

.locationAndTypeContainer, .HoursAndPriceContainer{
  display: flex;
  flex-direction: column;
}
.location, .type{
  position: absolute;
  top: 5em;
  left: 1em;
  right: 1em;
  bottom: 5em;
  max-width: 35em;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: left;
  overflow-y: auto;
}

  .date, .time {
    font-weight: 650;
    border: none;
    border-radius: 12px;
    -webkit-appearance: none; /* Remove default styling on iOS */
    -moz-appearance: none; /* Remove default styling on Firefox */
    appearance: none; /* Remove default styling on other browsers */
    /* Add your desired styles here to match the rest of the inputs */
  }
  /*----------Form deletion buttons----------*/

  .submitContainer, .deleteContainer{
    display: flex;
    justify-content: center;
    position: relative;
  }.submitButton, .disabledSubmitButton, .nextButton, .publishMobileButton, .nextButtonDesktop, .publishButtonDesktop, .deleteContainer button{
    margin-top: 0.5em;
    padding: 5px;
    width: 100%;
    height: 2.5em;
    font-size: 1.1em;
    text-align: center;
    font-family: 'Nunito';
    font-weight: 450;
    color: white;
    background-color: rgb(50, 85, 160);
    border-radius: 12px;
    cursor: pointer;
    border: none;
  }.nextButton{
    font-weight: 650;
  }.nextButtonDesktop{
    border-radius: 6px;
    font-weight: 650;
  }.deleteContainer{
    position: fixed;
    top: 0em;
    right: 1em;
  }.disabledSubmitButton{
    background-color: rgba(128, 128, 128, 0.6);
  }.deleteContainer button{
    color: rgb(44, 44, 44);
    background-color: rgb(194, 63, 56, 0);
    font-size: 1em;
    font-weight: 300;
  }.publishMobileButton{
    position: absolute;
    width: calc(100% - 2em);
    bottom: 1em;
    left:50%;
    transform: translate(-50%);
  }

  .SignInMessage{
    font-family: 'Nunito';
    font-size: 1.2em;
    text-align: center;
    font-weight: bold;
    margin-top: 10vh;
}

.signInButton{
    
}
.mobilePostSuccessMessageContainer{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate( -50%, -50%);
  justify-content: center;
  background-color: rgb(255, 255, 255);
  width: calc(100% - 2em);
  padding-bottom: 1em;
  height: fit-content;
  border-radius: 12px;
  z-index: 9999;
}.mobileSubmittedPostTitle{
  margin-top: 1.5em;
  color: rgb(0, 0, 0);
  text-align: left;
  font-size: 1.6em;
  font-family: 'Nunito';
  font-weight: bold;
}.mobileSubmittedPostMessage{
  margin-top: 1em;
  margin-left: 2.2em;
  text-align: left;
  color: rgb(128, 128, 128);
  font-size: 1.1em;
  font-family: 'Nunito';
  font-weight: normal;
}.mobileGotItButton{
  margin-top: 1em;
  margin-bottom: 0em;
  margin-left:50%;
  padding: 0.5em 5em;
  transform: translateX(-50%);
  font-size: 1.2em;
  background-color: black;
  color: white;
  border: none;
  border-radius: 50px;
  white-space: nowrap;
  cursor: pointer;
}.taskText{
  color: black;
  font-weight: bold;
  cursor: pointer
}

.hoursInputContainer{
  display: flex;
  flex-direction: row;
}
/*----------Desktop----------*/

.desktopContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9997;
  padding: 1em;
  width: 60em;
  height: 40em;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
  backdrop-filter: blur(15px);
  box-shadow: 10px solid black;
  border-radius: 12px;
  overflow-y: auto;
}

.postSuccessMessageContainer{
  position: fixed;
  padding: 1em 2em;
  top: 50%;
  left: 50%;
  justify-content: center;
  text-align: center;
  transform: translate(-50%,-50%);
  font-size: 1em;
  font-family: 'Nunito';
  font-weight: bold;
  background-color: white;
  width: 30em;
  max-width: 80%;
  border-radius: 12px;
  z-index: 9999;
}.submittedPostTitle{
  margin-top: 0em;
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 1.6em;
  font-family: 'Nunito';
  font-weight: bold;
}.submittedPostMessage{
  margin-top: 1em;
  text-align: left;
  color: rgb(128, 128, 128);
  font-size: 1.1em;
  font-family: 'Nunito';
  font-weight: normal;
}.gotItButton{
  margin-top: 1em;
  padding: 0.5em 5em;
  font-size: 1.2em;
  background-color: black;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  white-space: nowrap;
}

.typesContainer, .desktopTypeContainer{
  margin-top: 2em;
  overflow-y: auto;
  background-color: rgb(245, 245, 245);
  border-radius: 12px;
}.desktopTypeContainer{
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  margin-top: 8em;
  margin-bottom: 4em;
  overflow-x: hidden;
  overflow-y: auto;
}

.test{
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.suggestedTypesOptions ,.selectedTypeOption{
  margin-left: auto;
  margin-right: auto;
  font-size: 1em;
  font-family: 'Nunito';
  font-weight: 600;
  text-align: center;
  width: 70%;
  max-width: 20em;
  padding: 0.5em;
  color: rgb(44, 44, 44);
  height: fit-content;
  border: 1px solid rgb(44, 44, 44);
  border-radius: 8px;
  background-color: white;
  user-select: none;
  cursor: pointer;
}.selectedTypeOption{
  background-color: rgb(66, 110, 207);
  border: 1px solid rgb(66, 110, 207);
  color: rgb(255, 255, 255);
  margin-bottom: 0;
}

.selectedTypeButton{
  padding: 1em;
  color: rgb(161, 161, 161);
  font-family: 'Nunito';
  text-align: center;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-weight: bold;
  color:rgb(44, 44, 44);
  cursor: pointer;
}

.selectTypeTitle{
  position: relative;
  margin-top: 0em;
  padding-top: 1em;
  padding-bottom: 0.5em;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  font-family: 'Nunito';
  color: rgb(44, 44, 44);
}

.goBackButton{
  padding: 5px;
  width: 40px;
  height: 2.5em;
  font-size: 1em;
  text-align: center;
  font-family: 'Nunito';
  color: white;
  background-color: rgb(50, 80, 160);
  border-radius: 50%;
  cursor: pointer;
  border: none;
  z-index: 9997;
}

.nextButtonContainer{
  position: fixed;
  display: flex;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1em;
  width: calc(100% - 2em);
}.nextButtonContainerDesktop{
  position: fixed;
  top: 0;
}

.priceInputContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid black;
  border-radius: 12px;
  background-color: whitesmoke;
  padding: 0.5em;
  max-width: 35em;
  margin-left: auto;
  margin-right: auto;
}

.nextButtonDesktop, .publishButtonDesktop{
  position: fixed;
  left: 50%;
  transform: translateX(calc(-50% + 12.5em));
  top: 6.5em;
  width: fit-content;
  padding-left: 1em;
  padding-right: 1em;
  z-index: 9999;
}.publishButtonDesktop{
  left: 50%;
  transform: translateX(-50%);
  top: 20em;
}

.stepTwoContainer{
  max-width: 35em;
  margin-left: auto;
  margin-right: auto;
}

.categoryName{
  font-size: 1.2em;
  text-align: center;
  font-family: 'Nunito';
  font-weight: 750;
  margin-top: 3em;
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}