.headerlogo{  
  position: fixed;
  top: 0.65em;
  left: 1em;
  width: 23px;
  height: 23px;
  z-index: 9996;
}.slideUp{
    animation: slideUp 0.3s ease-out;
    animation-fill-mode: forwards;
    right: -88%;
  }.slideDown{
    animation: slideDown 0.3s ease-out;
    animation-fill-mode: forwards;
    right: 88%;
  }@keyframes slideUp {
    from {
        right: -88%; /* Start position, hidden below the screen */
  }
  to {
    right: 0; /* End position, slide up to the bottom of the screen */
  }
  }@keyframes slideDown {
    from {
        right: 0%;
  }
  to {
    right: -88%;
  }
}

.accountSettingsWindow{
  position:fixed;
  top: 4.5em;
  right: 1em;
  width: fit-content;
  height:auto;
  padding: 1em 0;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 6px rgb(0, 0, 0, 0.5);
  border-radius: 8px;
  z-index: 9998;
}

.notificationButton, .messages, .searchButton {
  position: fixed;
  top: 0.9em;
  right: 7em;
  width: 18px;
  height: 18px;
  z-index: 9996;
}.searchButton{
  right: 7em;
}.messages{
  width: 19px;
  height: 19px;
  right: 4.5em;
}

.headerSettings {
  position: fixed;
  top: 0.45em;
  right: 1em;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 9996;
}

.accountSettingsWindowBackground{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0,0);
  z-index: 9997;
}

.accountSettingElement{
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding-left: 1em;
  padding-right: 2em;  
  border: none;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
}.accountSettingElement:hover{
  color: rgb( 50, 85, 160);
  background-color: rgba(50, 85, 160, 0.1);
}
.accountSettingsButton{
  padding: 0 0em;
  font-size: 1.35em;
  font-family: 'Nunito';
  font-weight: 650;
  padding-left: 1em;
  border-radius: 4px;
}
.signInSignUp{
  display: flex;
  position: fixed;
  top: 0.45em;
  right: 1em;
  width: fit-content;
  z-index: 9996;
  font-size: 1em;
  font-family: 'Nunito';
  font-weight: 700;
  background-color: rgb(50,85,160);
  box-shadow: 0 0px 0px rgba(0, 0, 0, 1);
  border-radius: 8px;
  color: white;
  padding: 0.5em 1.5em;
}.signInSignUp p{
  margin: 0;
}

.button {
  margin:0 auto;
  margin-top: 0.3em;
  width: 22px;
  height: 22px;
  padding: 0px 16px;
  z-index: 9996;
}

.taskSelectionWindow{
  display: flex;
  flex-direction: column;
  text-align: center;
  position: fixed;
  top: 5em;
  right: 10em;
  background-color: white;
  box-shadow: 0 0 6px rgb(0, 0, 0, 0.5);
  border-radius: 8px;
  font-size: 1em;
  font-family: 'Nunito';
  z-index: 9995;
  cursor: pointer;
}.taskSelectionWindow p{
  margin:0.5em 0;
  padding:0.5em 1em;
}.taskSelectionWindow p:hover{
  color: rgba(50, 85, 160);
  background-color: rgba(50, 85, 160, 0.1);
}

.mobileNavigationBarButtons{
  margin:0 auto;
  margin-top: 0.3em;
  width: 18px;
  height: 18px;
  padding: 0px 16px;
  z-index: 9996;
}

.mobileCreateButton{
  margin:0 auto;
  width: 34px;
  height: 34px;
  padding: 0px;
  border-radius: 50%;
}

.empty{
  width: 25px;
  height: 25px;
  background-color: rgba(129, 133, 143, 0.3);
  border-radius: 50%;
}


.notificationNumber, .mobileNotificationNumber, .mobileMessagesNumber{
  position: fixed;
  top: 1.5em;
  right: 3.9em;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: rgb(50, 85, 160);
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
}.notificationNumber p, .mobileNotificationNumber p, .mobileMessagesNumber p{
  padding: 0.5em;
  font-size: 0.7em;
  font-family: 'Nunito';
  font-weight: 600;
  color: white;
}.mobileNotificationNumber{
  top: 0.35em;
  right: 6.5em;
  z-index: 9999;
}.mobileMessagesNumber{
  background-color: rgb(255, 58, 58);
}.mobileMessagesNumber{
  top: 0.35em;
  right: 4em;
  z-index: 9999;
}

.hidden{
  display: none;
}
/*-----------Desktop Version-----------*/

.leftSidenavigationButtonsContainer{
  display: flex;
  flex-direction: row;
  position: fixed;
  height: auto;
  top: 0em;
  left: 13em;
  margin-right: 3.5em;
  align-items: right;
  user-select: none;
  z-index: 9997;
}
.rightSidenavigationButtonsContainer{
  display: flex;
  flex-direction: row;
  position: fixed;
  height: auto;
  top: 0em;
  right: 1em;
  margin-top: 0.6em;
  margin-right: 3em;
  align-items: right;
  user-select: none;
  z-index: 9997;
}

.desktopHeaderLogo{
  position: fixed;
  top:-2.25em;
  left: 2em;
}
.accountSettingsContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 1.6em;
  right: 1em;
}
.profileContainer, .searchContainer, .savedContainer, .tasksContainer, .createContainer, .notificationsContainer{
  display:flex;
  width: fit-content;
  align-items: center;
  position: relative;
  margin-top: 1.5em;
  padding: 0.5em;
  cursor: pointer;
  border-radius: 4px;
  border: 0;
  background-color: rgb(0, 0, 0, 0);
}.desktopHeaderLogo img, .profileContainer img, .searchContainer img, .savedContainer img, .tasksContainer img, .createContainer img, .notificationsContainer img, .accountSettingsContainer img, .theme img, .icon{
  margin:0;
  width: 20px;
  height: 20px;
}.desktopHeaderLogo img{
  margin-top: 2em;
  width: 110px;
  height: 60px;
}
.searchContainer p, .profileContainer p, .savedContainer p, .tasksContainer p, .createContainer p, .notificationsContainer p{
  margin-top:0;
  margin-bottom:0;
  margin-left: 0.5em;
  margin-right: 0.5em;
  font-size: 1.35em;
  font-family: 'Nunito';
  color: rgb(44, 44, 44)
}.signInTextDesktop{
  display: flex;
  position: fixed;
  top: 0.9em;
  right: 1em;
  width: fit-content;
  z-index: 9996;
  font-size: 1em;
  font-family: 'Nunito';
  font-weight: 700;
  background-color: rgb(50,85,160);
  border-radius: 8px;
  color: white;
  padding: 0.5em 1.5em;
  cursor: pointer;
  border: 0;;
}.accountSettingsContainer img{
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.desktopHeaderLogo{
  margin-top: 0.3em;
}.createContainer{
  margin-top: 1.5em;
}.createContainer p{
  color: rgb(44, 44, 44);
  font-family: 'Nunito';
  font-size: 1em;
}
.selectedButton{
  font-weight: 800;
}.deselectedButton{
  font-weight: 650;
}
.profileContainer:hover,
.searchContainer:hover,
.savedContainer:hover,
.tasksContainer:hover,
.notificationsContainer:hover {
  background-color: rgba(50, 85, 160, 0.1);
}
.logoutContainer{
  font-weight:bold;
  width: fit-content;
  margin-top: auto;
  margin-bottom: 2em;
  padding: 0.5em;
  text-align: center;
  align-self: center;
  cursor: pointer;
}.logoutContainer :hover{
  background-color: rgba(255, 255, 255, 1);
}.logoutContainer p{
margin:0.5em 1em;
font-size: 1.1em;
font-family: 'Nunito';
color: #525252;
}

.theme{
  position: absolute;
  top:1em;
  right:1em;
}

.createTaskLine {
  position: absolute;
  margin-top: 4em;
  width: 0em;
  margin-left: 0.5em;
  height: 3px;
  background-color: rgb(50, 85, 160);
  border-radius: 1px;
  transition: width 0.3s ease; /* Add a smooth transition effect */
}

.hoveredLine {
  width: 8em;
  transition: width 0.3s ease; /* Add transition for hover state as well */
}



@media(max-width: 740px){
  .rightSidenavigationButtonsContainer{
    margin-right: 2.5em;
  }
  .profileContainer p, .savedContainer p, .tasksContainer p, .notificationsContainer p, .theme p{
    display: none;
  }
  .desktopHeaderLogo{
    top: -1em;
    left: 1em;
  }
  .desktopHeaderLogo img{
    margin-top: 1.5em;
    width: 70px;
    height: 45px;
  }
  .leftSidenavigationButtonsContainer{
    left: 6.5em;
  }
  .taskSelectionWindow{
    right: 5em;
  }
  .notificationNumber{
    right: 3.4em;
  }
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}