.mobileTaskBody{
    position: relative;
    margin-top: 0;
    margin-bottom: 1em;
    padding: 1em;
    border-radius: 12px;
    border: 1px solid rgb(215, 215, 215);
    border-top: none;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 4px 4px 4px -4px rgb(175, 175, 175), 4px 4px 4px -4px rgb(175, 175, 175), -4px 4px 4px -4px rgb(175, 175, 175);
    -moz-box-shadow:    4px 4px 4px -4px rgb(175, 175, 175), 4px 4px 4px -4px rgb(175, 175, 175), -4px 4px 4px -4px rgb(175, 175, 175);
    box-shadow:         4px 4px 4px -4px rgb(175, 175, 175), 4px 4px 4px -4px rgb(175, 175, 175), -4px 4px 4px -4px rgb(175, 175, 175);
    overflow: hidden;
  }.desktopTaskBody{
    position: relative;
    margin-top: 0;
    margin-bottom: 1em;
    padding: 1em;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgba(175, 175, 175, 1);
    overflow: hidden;
  }

.header{
    display:flex;
}

.profilePicture, .vendorProfilePicture{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}.vendorProfilePicture{
    width: 35px;
    height: 35px;
}

.dateAndCancelation{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.resultIcon{
    width: 18px;
    height: 18px;
}

.vendorContainer{
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    margin: 0;
    padding: 0;
} .vendorContainer img{
    margin-left: 0.5em;
    padding: 0;
}.mineOrAssigned{
    margin: 0;
    color: rgb(44, 44, 44);
    font-family: 'Nunito';
    font-weight: 500;
}.reviewUserButton{
    margin: 0;
    margin-right: 0.5em;
    margin-left: 1em;
    padding:0.4em 1.5em;
    font-size: 1em;
    font-family: 'Nunito';
    color: rgb(255, 255, 255);
    background-color: rgba(217, 124, 48);
    border-radius: 4px;
    text-align: right;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.title{
    margin: auto;
    margin-left: 0;
    margin-right: 1.4em;
    align-items: center;
    font-size: 1.3em;
    font-family: 'Nunito';
    font-weight: 650;
    color:rgb(44, 44, 44);
    user-select: none;
}

.details {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    color:rgb(44, 44, 44);
} .detailContainer, .locationContainer{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    margin-top: 1em;
    margin-right: 1em;
    padding: 5px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    user-select: none;
  }.locationContainer{
    padding-left: 0;
    box-shadow: none;
  }.detailText{
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 0.9em;
    font-weight: 650;
    font-family: 'Nunito';
    color:rgb(44, 44, 44);
    white-space: nowrap;
  }

.extraInformation{
    padding: 5px;
    font-size: 1em;
    color:rgb(44, 44, 44);
    border-radius: 12px;
    font-family: 'Nunito';
    user-select: none;
}


.details p:first-child{
    margin-left: 0em;
}

.detail{
    margin: 0;
    margin-top: 1em;
    padding: 5px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    align-self: center;
    text-align: center;
    font-size: 0.85em;
    font-family: 'Nunito';
    font-weight: bold;
    color:rgb(44, 44, 44);
    user-select: none;
}

.descriptionExpanded {
    font-size: 1em;
    color: rgb(44, 44, 44);
    margin: auto;
    margin-top:1em;
    white-space: normal;
    font-family: 'Nunito';
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    max-height: 80em;
}

.descriptionCollapsed {
    font-size: 1em;
    font-family: 'Nunito';
    color: rgb(44, 44, 44);
    margin: auto;
    margin-top:1em;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    user-select: none;
    transition: max-height 0.3s ease-in-out;
    max-height: 0em;
}
.descriptionCollapsed ::after{
    margin-top:0;
}

.viewDescription{
    display:flex;
    justify-content: center;
}.viewDescription :first-child{
    margin-top: 1em;
    margin-bottom:0;
    font-size: 0.9em;
    font-weight: bold;
    font-family: 'Nunito';
    color: rgb(44, 44, 44);
    cursor:pointer;
}

.desiredDate{
    color:rgb(44, 44, 44);
    font-size: 0.95em;
    font-family: 'Nunito';
}

.resultContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin:0;
    margin-top: 1em;
    margin-bottom: 0em;
    user-select: none;
}
.result{
    font-weight: bold;
    margin-left: 0.5em;
    margin-right: 0.5em;
}


.background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9999;

}

.filterIcon{
    width: 15px;
    height: 15px;
    margin-right: 0.2em;
}
.reportButton{
    width: 20px;
    height: 20px;
    cursor: pointer;
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}