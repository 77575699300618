.mobileTaskBody{
  position: relative;
  margin-top: 0;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 12px;
  border: 1px solid rgb(215, 215, 215);
  border-top: none;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 4px 4px 4px -4px rgb(175, 175, 175), 4px 4px 4px -4px rgb(175, 175, 175), -4px 4px 4px -4px rgb(175, 175, 175);
  -moz-box-shadow:    4px 4px 4px -4px rgb(175, 175, 175), 4px 4px 4px -4px rgb(175, 175, 175), -4px 4px 4px -4px rgb(175, 175, 175);
  box-shadow:         4px 4px 4px -4px rgb(175, 175, 175), 4px 4px 4px -4px rgb(175, 175, 175), -4px 4px 4px -4px rgb(175, 175, 175);
  overflow: hidden;
}.desktopTaskBody{
  position: relative;
  margin-top: 0;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(175, 175, 175, 1);
  overflow: hidden;
}

.taskBodyInitial{
  max-height: 80em;
}

.taskBodyUnbanned{
  animation: taskBodyUnbanned 0.3s ease-in-out;
  animation-fill-mode: forwards;
}  
.taskBodyBanned{
  animation: taskBodyBanned 0.3s ease-in-out;
  animation-fill-mode: forwards;
}
@keyframes taskBodyUnbanned {
  from {
    max-height:3em;
  }
  to {
    max-height:80em;
  }
}@keyframes taskBodyBanned {
  from {
    max-height: 80em;
  }
  to {
    max-height: 3em;
  }
}

.bannedListing{
  margin-bottom: 0em;
  display: flex;
  align-items: center;
}.bannedListing p{
  margin-left: 1em;
  font-size: 0.95em;
  font-weight: bold;
  font-family: 'Nunito';
}.bannedListing button{
  margin-left: auto;
  margin-right: 1em;
  padding: 5px;
  font-size: 1.1em;
  font-weight: bold;
  font-family: 'Nunito';
  color: white;
  background-color: rgb(0, 122, 255);
  border: none;
  border-radius: 50px;
  user-select: none;
  cursor: pointer;
}

.header{
    display:flex;
}

.profilePicture{
    width: 40px;
    height: 40px;
    margin-right: 0.5em;
    border-radius: 50%;
    /* cursor: pointer; */
}

.title{
    margin: auto;
    margin-left: 0;
    margin-right: 1.4em;
    align-items: center;
    font-size: 1.3em;
    font-family: 'Nunito';
    font-weight: 650;
    color:rgb(44, 44, 44);
    user-select: none;
}

.details {
    display: flex;
    flex-wrap: wrap;
 }

 .detailContainer, .locationContainer{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  margin-top: 1em;
  margin-right: 1em;
  padding: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  user-select: none;
}.locationContainer{
  padding-left: 0;
  box-shadow: none;
}.detailText{
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 0.9em;
  font-weight: 650;
  font-family: 'Nunito';
  color:rgb(44, 44, 44);
  white-space: nowrap;
}

.expansionButton{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.applyButton{
    width: 70%;
    max-width: 15em;
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    font-size: 1.1em;
    text-align: center;
    font-weight: normal;
    font-family: 'Nunito';
    color: white;
    background-color: rgb(0, 122, 255);
    user-select: none;
    border-radius: 6px;
    border:none;
    cursor: pointer;
}

.extraInformation{
    padding: 5px;
    font-size: 1em;
    color:rgb(44, 44, 44);
    border-radius: 12px;
    font-family: 'Nunito';
    user-select: none;
}


 .details p:first-child{
    margin-left: 0em;    
 }
.detail{
    margin: 0;
    margin-top: 1em;
    padding: 5px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    align-self: center;
    text-align: center;
    font-size: 0.85em;
    font-family: 'Nunito';
    font-weight: bold;
    color:rgb(44, 44, 44);
    user-select: none;
}

.descriptionLine{
  margin-left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 1px;
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: rgb(230, 230, 230);
}

.descriptionContainer {
    display: flex;
    flex-direction: column;
    font-size: 1.05em;
    color: rgb(44, 44, 44);
    margin: auto;
    margin-top: 0em;
    padding: 0em 0.5em;
    white-space: normal;
    font-family: 'Nunito';
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }

  .initialDescriptionHeight{
    max-height: 0em;
  }

  .descriptionExpanded{
    animation: descriptionExpanded 0.5s ease;
    animation-fill-mode: forwards;
  }  
  .descriptionCollapsed{
    animation: descriptionCollapsed 0.5s ease;
    animation-fill-mode: forwards;
  }
  @keyframes descriptionExpanded {
    from {
      max-height:0em;
    }
    to {
      max-height:80em;
    }
  }@keyframes descriptionCollapsed {
    from {
      max-height: 80em;
    }
    to {
      max-height: 0em;
    }
  }

  .viewDescription{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1em;
    background-color: white;
  }.viewDescription :first-child{
    margin:0;
    padding:0;
    font-size: 0.9em;
    font-weight: bold;
    font-family: 'Nunito';
    color: rgb(44, 44, 44);
    cursor:pointer;
  }

.dateBookmarkContainer{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5em;
}

.desiredDate{
  margin:0;
  color:rgb(111, 111, 111);
  font-size: 0.85em;
  font-family: 'Nunito';
  user-select: none;
}



.hidden{
  display: none;
}

.EditShareContainer{
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 1em;
  right: 1em;
 }

 .vendorContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
 }

 .applicantsContainer, .completeButton{
  display: flex;
  align-items: center;
  padding: 0.5em 0.5em;
  width: fit-content;
  box-shadow: 0px 2px 2px rgb(50, 85, 160, 1);
  border-radius: 4px;
  cursor: pointer;
 }.completeButton{
  box-shadow: none;
  position: relative;
  margin-left: auto;
 }

 .applicantsText, .completionText{
  font-size: 1.1em;
  color:rgb(50, 85, 160);
  font-family: 'Nunito';
  font-weight: 650;
  margin:0;
  margin-left: 0.3em;
}.completionText{
  font-size: 1em;
  color:rgb(44, 44, 44);
  font-weight: 300;
}
.shareIcon, .editIcon, .candidatesIcon, .applicantIcon{
  width: 23px;
  height: 23px;
  cursor: pointer;
  z-index: 999;
}.shareIcon{
  position: relative;
  margin-top: 1.5em;
}.applicantIcon{
  right: 0;
  width: 37px;
  height: 37px;
  border-radius: 50%;
}.vendorContainer{
}.contactVendorText{
  margin: 0;
  margin-right: 0.5em;
  padding:0.4em 1.5em;
  font-size: 1em;
  font-family: 'Nunito';
  color: rgb(255, 255, 255);
  background-color: rgba(50, 85, 160);
  border-radius: 4px;
  text-align: right;
  cursor: pointer;
}.candidatesIcon{
  width: 20px;
  height: 20px;
}

.filterIcon{
  width: 15px;
  height: 15px;
  margin-right: 0.2em;
}
.active {
  background-color: #66ff00;
  color: black;
}
.shareIcon, .abandonTaskStyle{
  width: 25px;
  height: 25px;
  margin-top: 1em;
  margin-left: 0.5em;
}.abandonTaskStyle{
  width: 15px;
  height: 15px;
  margin-top:0;
}

.completionContainer{
  display: flex;
  justify-content: right;
  align-items: center;
}
.loadingIcon{
  position: absolute;
  margin-top: 0.25em;
  width: 30px;
  height: 30px;
  right: 1em;
  animation: rotate 2s linear infinite; /* Adjust the duration and timing function as needed */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}