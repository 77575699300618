.container{
  display: flex;
  flex-direction: column;
  padding: 0em;
}

.inputLocation{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 4px);
  max-width: 35em;
  height: fit-content;
  margin-top: 0em;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  font-size: 1em;
  background-color: rgb(245, 245, 245);
  border-radius: 12px;
  outline: none;
  color: rgb(44, 44, 44);
  font-size: 1em;
  font-weight: 450;
}

.selectedInputLocation{
  border: 2px solid rgb(50, 85, 160) !important;
}
.unselectedInputLocation{
  border: 2px solid rgb(0, 0, 0) !important;
}

.locationInput{
  width: 100%;
  right: auto;
  height: 1em;
  padding: 1em;
  padding-left: 0.5em;
  border:none;
  color: rgb(44, 44, 44);
  background-color: whitesmoke;
  font-size: 1em;
  border-radius: 12px;
  outline: none;
  resize: none;
}
.desktopOptionsContainer{
    width: 100%;
    height: 14em;
    overflow: auto;
    padding: 0em;
    border-top: none;
    border-radius: 4px;
    z-index: 9998;
  }.mobileOptionsContainer{
    height: 20em;
    padding:0em;
    overflow: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0px 0px rgba(0, 0, 0, 0.3);
    z-index: 9999;
  }
  
  .option, .optionMobile{
    margin: 0 0em;
    padding: 1em;
    padding-left: 0.5em;
    font-size: 0.9em;
    font-family: 'Nunito';
    font-weight: 600;
    color: #444444;
    cursor: pointer;
    overflow-y: hidden;
  }.selectedOption{
    background-color: rgb(236, 236, 236);
    border-radius: 4px;
  }
  .clearButton {
    cursor: pointer;
    padding-left: 1em;
    padding-right: 1em;
    width: 15px;
    height: 15px;
    cursor: pointer;
    border-left: 1px solid rgb(211, 211, 211);
  }

  .goBackButton{
    width: 40px;
    height: 2.5em;
    font-size: 1em;
    text-align: center;
    font-family: 'Nunito';
    color: white;
    background-color: rgb(50, 80, 160);
    border-radius: 50%;
    cursor: pointer;
    border: none;
    z-index: 9997;
    margin-bottom: 1em;
  }

  .suggestedLocations, .suggestedLocationsMobile{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0.5em;
  }
  .suggestedLocations:hover{
    background-color: rgb(236, 236, 236);
  }

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}