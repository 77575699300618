.container{
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.background{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-Index: 9998;
}
.deleteConfirmation{
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    padding: 1em;
    z-index: 9999;
    height: auto;
    width: 18em;
    border-radius: 12px;
    background-color: rgb(255, 255, 255);
  }.deleteConfirmation p:first-child{
    text-align: center;
    font-family: 'Nunito';
    font-size: 1.2em;
    font-weight: bold;
    color:rgb(44, 44, 44);
  }.deleteConfirmationMessage{
    text-align: center;
    font-family: 'Nunito';
    font-size: 1.1em;
    color:rgb(44, 44, 44);
  }
  .deleteConfirmationButtons, .continueButton{
    display: flex;
    justify-content: space-evenly;
  }
  .deleteConfirmationButtons button, .continueButton button{
    margin-top: 1em;
    font-size: 1em;
    width: 7em;
    height: 2.5em;
    font-family: 'Nunito';
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }.cancelButton{
    color: rgb(100, 100, 100);
    background-color: rgb(0, 0, 0,0);
  }.deleteConfirmationButton{
    color: rgb(245, 245, 245);
    background-color: rgb(194, 63, 56);
  }.pressedDeleteConfirmationButton{
    color: rgb(245, 245, 245);
    background-color: rgba(200, 200, 200, 1);
  }

  .continueButton button{
    border-radius: 50px;
    font-weight: bold;
    background-color: rgb(0, 0, 0);
    color: rgb(245, 245, 245);
  }

  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
  }