.mobileHeader {
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  /* border-bottom: 1px solid rgb(211, 211, 211); */
  font-weight: 600;
  color: rgb(44, 44, 44);
  position: fixed;
  height: 3em;
  top: 0px;
  left: 0em;
  right: 0px;
  z-index: 9995;
}
  
.category {
  margin-top: 0.85em;
  margin-left: 0.5em;
  font-size: 1.2em;
  font-family: 'Nunito';
  font-weight: 750;
}
.headerlogo{
  margin-top: -0.2em;
  margin-left: 0.5em;
  width: 80px;
  height: 45px;
  z-index: 9996;
}

/*----------Desktop Version----------*/

.desktopHeader {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  /* border-bottom: 1px solid rgb(211, 211, 211); */
  border-right: 0px solid rgb(211, 211, 211);
  font-weight: 600;
  color: rgb(44, 44, 44);
  top: 0;
  width: 100%;
  height: 4.5em;
  z-index: 9995;
}

.desktopCategory{
  margin: 0.5em;
  font-size: 1.2em;
  font-family: 'Nunito';
  font-weight: 700;
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}