.desktopCopiedToClipboardMessageContainer{
  position: fixed;
  bottom: 2em;
  left: 50%;
  padding: 0 1em;
  transform: translateX(-50%);
  z-index: 9999;
  user-select: none;
  background-color: rgb(50, 85, 160, 1);
  border-radius: 4px;
  cursor: default;
}
.mobileCopiedToClipboardMessageContainer{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2.5em;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    user-select: none;
    cursor: default;
    animation: copiedTransition 1s ease-in-out;
  }
  
  .copiedToClipboardMessage{
    color: whitesmoke;
    font-size: 1.1em;
    font-family: 'Nunito';
    font-weight: 500;
    white-space: nowrap;
  }
  
  .fadeIn{
    animation: fadeIn 0.5s ease;
    animation-fill-mode: forwards;
  }
  .fadeOut{
    animation: fadeOut 0.5s ease;
    animation-fill-mode: forwards;
  }
  @keyframes fadeIn {
    from {
      top: 0em;
      background-color: rgb(50, 85, 160, 0);
  
    }
    to {
      top: 3em;
      background-color: rgb(50, 85, 160, 1);
  
    }
  }@keyframes fadeOut {
    from {
      top: 3em;
      background-color: rgb(50, 85, 160, 1);
  
    }
    to {
      top: 0em;
      background-color: rgb(50, 85, 160, 0);
  
    }
  }

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}