.container{
  margin: 0 0em;
}
.hidden{
  display: none;
}

.backgroundColor{
  position: absolute;
  top: 3.5em;
  left: 0;
  width: 100%;
  height: 23em;
  background-color: rgb(247, 249, 252);
  z-index: 1;
}

.desktopWelcomeText{
  position: absolute;
  text-align: left;
  margin:0;
  padding: 0;
  top: 3.3em;
  color: rgb(17, 36, 62);
  font-family:"nunito";
  font-weight: 800;
  /* font-style: italic; */
  font-size: 2.8em;
  z-index: 2;
}
.mobileWelcomeText{
  position: absolute;
  text-align: left;
  top: 2.8em;
  left: 0.5em;
  color: rgb(17, 36, 62);
  font-family: 'Nunito';
  font-weight: 800;
  /* font-style: italic; */
  font-size: 2.1em;
  z-index: 2;
}.titleHighlightedFont{
  font-family: 'Nunito';
  color: rgb(50, 85, 160);
  font-weight: 900;
  /* font-style: italic; */
}.createTaskMessageContainer{
  position:relative;
  z-index: 1;
  margin-top: 1em;
  margin-bottom: 0em;
}.createTaskMessage, .learnAboutUsText{
  font-family: 'Nunito';
  font-weight: 600;
  color:rgb( 44, 44, 44);
}.clickHereMessage{
  font-weight: 700;
  color: rgb( 50, 85, 160);
  text-decoration: underline;
  cursor: pointer;
}.questionMarkStyle{
  margin-right: 0.5em;
  width: 15px;
  height: 15px;
}.learnAboutUsContainer{
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
}.learnAboutUsText{
  font-size: 0.9em;
  font-weight: 600;
}

.extraSpace{
    margin-top: 14em;
}.noExtraSpace{
  margin-top: 0;
}

.fadeIn{
    animation: fadeIn 0.3s ease-out;
    animation-fill-mode: forwards;
    margin-top: 14em;
  }.fadeOut{
    animation: fadeOut 0.3s ease-out;
    animation-fill-mode: forwards;
    margin-top: 0;
  }

@keyframes fadeIn {
    from {
        margin-top: 0;

  }
  to {
    margin-top: 14em;

  }
}@keyframes fadeOut {
    from {
      margin-top: 14em;

  }
  to {
    margin-top: 0%;

  }
}

.grouppedSearchMessages{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1em;  
}

.alternativeLocations{
  margin-top: 0;
  font-size: 1em;
  font-family:"nunito";
  font-weight: 500;
}

.alterlativeLocationsStyle{
  position: fixed;
  padding: 1em;
  width: 20em;
  top: 30%;
  left: 50%;
  transform: translate(-50%);
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 6px rgb(0, 0, 0, 0.6);
  border-radius: 4px;
  z-index: 9999;
}

.alterlativeLocationsText{
  font-size: 1em;
  font-family:"nunito";
  font-weight: 500;
  color: rgb( 44, 44, 44);
  cursor: pointer;
}

.locationIcon{
  width: 18px;
  height: 18px;
  margin-right: 0.2em;
}

.backgroundAlternativeLocations{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb( 0, 0, 0, 0 );
  z-index: 9998;
}
 
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}