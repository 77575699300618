.mobileTaskBody{
  position: relative;
  margin-top: 0;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 12px;
  border: 1px solid rgb(215, 215, 215);
  border-top: none;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 4px 4px 4px -4px rgb(175, 175, 175), 4px 4px 4px -4px rgb(175, 175, 175), -4px 4px 4px -4px rgb(175, 175, 175);
  -moz-box-shadow:    4px 4px 4px -4px rgb(175, 175, 175), 4px 4px 4px -4px rgb(175, 175, 175), -4px 4px 4px -4px rgb(175, 175, 175);
  box-shadow:         4px 4px 4px -4px rgb(175, 175, 175), 4px 4px 4px -4px rgb(175, 175, 175), -4px 4px 4px -4px rgb(175, 175, 175);
  overflow: hidden;
}
.desktopTaskBody{
  position: relative;
  margin-top: 0;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(175, 175, 175, 1);
  overflow: hidden;
}
.taskBodyBanned{
  position: relative;
  margin-top: 0;
  margin-bottom: 1em;
  border-radius: 12px;
  border-bottom: 1px solid rgb(152, 152, 152);
  background-color: rgb(255, 255, 255);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2),
  0 0 0 transparent; /* This second shadow declaration removes the shadow from the top side */  overflow: hidden;
  max-height:3em;
  transition: max-height 0.5s ease-in-out;
}

.bannedListing{
  margin-bottom: 0em;
  display: flex;
  align-items: center;
}.bannedListing p{
  margin-left: 1em;
  font-size: 0.95em;
  font-weight: bold;
  font-family: 'Nunito';
}.bannedListing button{
  margin-left: auto;
  margin-right: 1em;
  padding: 5px;
  font-size: 1.1em;
  font-weight: bold;
  font-family: 'Nunito';
  color: white;
  background-color: rgb(0, 122, 255);
  border: none;
  border-radius: 50px;
  user-select: none;
  cursor: pointer;
}

.header{
    display:flex;
}

.client{
  width: 37px;
  height: 37px;
  border-radius: 50%;
  cursor: pointer;
}

.clientContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
 }

 .title{
  margin: auto;
  margin-left: 0;
  margin-right: 1.4em;
  align-items: center;
  font-size: 1.3em;
  font-family: 'Nunito';
  font-weight: 650;
  color:rgb(44, 44, 44);
  user-select: none;
}

.details {
  display: flex;
  flex-wrap: wrap;
 }

 .applyAndExtraInformation{
  display: flex;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 2em;
 }

.updateButton, .acceptButton, .cancelTaskButton{
  margin-top: 0em;
  margin-right: 1em;
  padding:0.4em 1.5em;
  font-size: 1.1em;
  text-align: center;
  font-weight: normal;
  font-family: 'Nunito';
  color: white;
  background-color: rgb(50, 85, 160);
  user-select: none;
  border-radius: 4px;
  border:none;
  cursor: pointer;
}.cancelTaskButton{
  padding: 5px 10px;
  background-color: red;
  border-radius: 50%;
}.disabledButton{
  background-color: rgba(128, 128, 128, 0.3);
}

.extraInformation{
  padding: 5px;
  font-size: 1em;
  color:rgb(44, 44, 44);
  border-radius: 12px;
  font-family: 'Nunito';
  user-select: none;
}
.trashCan{
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.details p:first-child{
  margin-left: 0em; 
}
.detail{
  margin: 0;
  margin-top: 1em;
  padding: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  align-self: center;
  text-align: center;
  font-size: 0.85em;
  font-family: 'Nunito';
  font-weight: bold;
  color:rgb(44, 44, 44);
  user-select: none;
} 
.detailContainer, .locationContainer{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  margin-top: 1em;
  margin-right: 1em;
  padding: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  user-select: none;
}.locationContainer{
  padding-left: 0;
  box-shadow: none;

}.detailText{
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 0.9em;
  font-weight: 650;
  font-family: 'Nunito';
  color:rgb(44, 44, 44);
  white-space: nowrap;
}

.expansionButton{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.filterIcon{
  width: 15px;
  height: 15px;
  margin-right: 0.2em;
}

.descriptionLine{
  margin-left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 1px;
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: rgb(230, 230, 230);
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  font-size: 1.05em;
  color: rgb(44, 44, 44);
  margin: auto;
  margin-top:0em;
  padding: 0em 0.5em;
  white-space: normal;
  font-family: 'Nunito';
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.initialDescriptionHeight{
  max-height: 0em;
}

.descriptionExpanded{
  animation: descriptionExpanded 0.5s ease;
  animation-fill-mode: forwards;
}  
.descriptionCollapsed{
  animation: descriptionCollapsed 0.5s ease;
  animation-fill-mode: forwards;
}
@keyframes descriptionExpanded {
  from {
    max-height:0em;
  }
  to {
    max-height:80em;
  }
}@keyframes descriptionCollapsed {
  from {
    max-height: 80em;
  }
  to {
    max-height: 0em;
  }
}

.viewDescription{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1em;
}.viewDescription :first-child{
  margin:0;
  padding:0;
  font-size: 0.9em;
  font-weight: bold;
  font-family: 'Nunito';
  color: rgb(44, 44, 44);
  cursor:pointer;
}

.hidden{
  display: none;
}

.dateBookmarkContainer{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
}
.contactVendorText{
  margin: 0;
  margin-right: 0.5em;
  padding:0.4em 1.5em;
  font-size: 1em;
  font-family: 'Nunito';
  color: rgb(255, 255, 255);
  background-color: rgba(50, 85, 160);
  border-radius: 4px;
  text-align: right;
  cursor: pointer;
}

.desiredDate{
  position: relative;
  margin:0;
  color:rgb(111, 111, 111);
  font-size: 0.85em;
  font-family: 'Nunito';
  user-select: none;
  z-index: -1;
}

.active {
  background-color: #66ff00;
  color: black;
}
.shareIcon{
  width: 25px;
  height: 25px;
  margin-top:1em;
}

.confirmationPending{
  font-size: 1em;
  font-family: 'Nunito';
  font-weight: 400;
  color: rgb(100, 100, 100);
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}