.background{
    position: fixed;
    top:0;
    left: 0;
    background-color: rgb(0, 0, 0, 0);
    width: 100%;
    height: 100%;
}
.container{
    position: absolute;
    width: 90%;
    max-width: 30em;
    height: 50vh;
    left:auto;
    transform: translateX(-50%);
}.countryCodeItem p{
    width: 100%;
    margin: 1em;
    margin-left:0;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    cursor: pointer;
}.countryCodeItem p:hover{
}
.countryCodesContainer{
    position: absolute;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
    border-top: none;
    width: fit-content;
    padding-left: 1em;
    padding-right: 5em;
    height:100%;
    overflow: scroll;
}.countryCodeItem p :hover{
    color: red;
}