/* Payment.module.css */

.paymentContainer {
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(243, 247, 253);
    padding: 20px;
    border-radius: 5px;
    width: 300px;
  }
  
  .paymentText {
    font-size: 1.2em;
    color: rgb(44, 44, 44);
    margin: 0 0 10px;
  }

.cardDetailsLabel, .paymentText{
    font-size: 1em;
    font-family: 'Nunito';
    font-weight: 400;  
}.cardDetailsLabel{
  font-weight: 350;
  margin-top: 2em;
}
  
  .paymentForm {
    width: 100%;
  }
  
  .paymentLabel {
    margin-bottom: 10px;
  }
  
  .paymentCardElement, .cardInformationContainer{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    width: auto;
  }

  .cardInformation{
    font-size: 0.9em;
    font-family: 'Nunito';
    color: rgb(44, 44, 44);
    background-color: rgb(0, 0, 0, 0);
    border: none;
    width: 100%;
    outline: none; /* Optional: Remove the default focus outline */
  }.errorBorder{
    border: 1.5px solid #ff0000;
  }
  
  .paymentButton {
    font-size: 1em;
    font-family: 'Nunito';
    font-weight:400;
    background-color: rgb(50, 85, 160);
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .paymentButton:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  .loadingIconStyle{
    margin-top: 0.5em;
    margin-bottom: -1em;
    width: 30px;
    height: 30px;
    margin-left: calc(50% - 10px);
    transform: translateX(-50%);
    animation: rotate 2s linear infinite; /* Adjust the duration and timing function as needed */
}


.errorStyle{
  font-size: 1em;
  font-family: 'Nunito';
  font-weight:400;
  color: rgb(217, 0, 0);
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}