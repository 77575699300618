.mobileContainer{
    position: absolute;
    margin-left: -1em;
    z-index: 999;
}

.desktopContainer{
    position: absolute;
    margin-top: 4em;
    z-index: 999;
}
.container{
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 38em;
    height: 3.2em;
    margin-bottom: 0.5em;
    padding: 0em;
    text-align: left;
    font-size: 1em;
    background-color: rgba(255, 255, 255, 1);
    border-bottom: none;;
    border-radius: 8px;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}.containerMobile{
    margin-top: -1.5em;
    max-width: calc(100% - 2em);
    margin-left: auto;
    margin-right: auto;
}
.mobileLocationInputContainer {
    text-align: left;
    font-size: 1em;
    color: #444444;
    font-family: 'Nunito';
    flex-grow: 1; /* Add this line to make the input stretch */
    margin: 0;
    padding: 0.7em;
    width: 100%;
    max-width: calc(100% - 2em);
    border: none;
    outline: none;
}
.desktopLocationInputContainer{
    text-align: left;
    font-size: 1em;
    color: #444444;
    font-family: 'Nunito';
    flex-grow: 1; /* Add this line to make the input stretch */
    margin: 0;
    margin: 0.7em;
    width: 100%;
    border: none;
    outline: none;
}

.clearButton {
    cursor: pointer;
    padding-left: 1em;
    padding-right: 1em;
    width: 15px;
    height: 15px;
    cursor: pointer;
    border-left: 1px solid rgb(211, 211, 211);
}

.desktopOptionsContainer{
    position: absolute;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 20em;
    max-height: 60vh;
    overflow: auto;
    margin-top: -0.5em;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(211, 211, 211);
    border-radius: 4px;
    box-shadow: 0 px 6px rgba(0, 0, 0, 0.3);
    z-index: 9995;
}.mobileOptionsContainer{
    position: absolute;
    width: calc(100% - 2em); /* Adjust the width to account for the right margin */
    height: 25vh;
    overflow: auto;
    margin-top: -0.5em;
    margin-left: 1em;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(211, 211, 211);
    border-radius: 4px;
    box-shadow: 0px 0px rgba(0, 0, 0, 0.3);
    z-index: 9995;
}

.option{
    margin: 0;
    padding-top:1em;
    padding-bottom:1em;
    padding-left: 1em;
    padding-right: 1em;
    font-size: 0.9em;
    font-family: 'Nunito';
    font-weight: bold;
    color: #444444;
    cursor: pointer;
}.option:hover{
    background-color: rgb(236, 236, 236);
    border-radius: 4px;
}.selectedOption{
    background-color: rgb(236, 236, 236);
    border-radius: 4px;
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}