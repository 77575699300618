.background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-Index: 9998;
  }.container{
      display: flex;
      justify-content: center; /* Center horizontally */
      align-items: center; /* Center vertically */
  }
  .connectConfirmation{
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
      padding: 1em;
      z-index: 9999;
      height: auto;
      width: 18em;
      border-radius: 12px;
      background-color: rgb(255, 255, 255);
    }.titleText{
      text-align: center;
      font-family: 'Nunito';
      font-size: 1.2em;
      font-weight: bold;
      color:rgb(44, 44, 44);
    }
    .connectConfirmationButtons{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .connectConfirmationButtons button{
      font-size: 1.2em;
      height: 2.5em;
      width: fit-content;
      margin-top: 1em;
      font-family: 'Nunito';
      border: none;
      border-radius: 8px;
      cursor: pointer;
    }.connectConfirmationButtons button:last-child{
      color: rgb(100, 100, 100);
      background-color: rgb(0, 0, 0, 0);
    }.confirmationButtonEnabled, .abandonTask{
      color: rgb(245, 245, 245);
      background-color: rgb(50, 85, 160);
      white-space: nowrap;
      padding-left: 1em;
      padding-right: 1em;
    }

    .abandonTask{
        background-color: rgb(190, 0, 0);
    }
    .disabledButton{
      background-color: grey;
    }

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}