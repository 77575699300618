
.containerMobileBackground{
  position: fixed;
  top: 6em;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 999;
}

.containerMobile{
  position: absolute;
  top: 6em;
  bottom: 1em;
  width: calc(100% - 2em);
  overflow: scroll;
  margin-left: 1em;
  background-color: rgb(245, 245, 245);
  border-radius: 16px;
}.lowContainerIndex{
  z-index: 998;
}.highContainerIndex{
  z-index: 999;
}

  .containerDesktop{
    margin-top: 1.5em;
    margin-left: 22em;
    background-color: rgb(245, 245, 245);
    border-radius: 14px;
    overflow-x: scroll;
    overflow-y: scroll;
  }
  
  .slideIn {
    animation: slideIn 0.4s ease-out;
    animation-fill-mode: forwards;
    transform: translateX(100%);
  }
  
  .slideOut {
    animation: slideOut 0.4s ease-out;
    animation-fill-mode: forwards;
    transform: translateX(-100%);
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }

.profilePictureDesktop, .profilePictureMobile{
  width: 130px;
  height: 130px;
  border-radius: 50%;
  align-self: center;
}
.contentContainerDesktop, .contentContainerMobile{
  display: flex;
  flex-direction: column;
  margin: 1em;
  margin-top: 2em;
}.contentContainerDesktop{
  margin-bottom: 3em;
}

.changeProfilePictureStyleContainer{
  display: flex;
  flex-direction: column;
}

.textButtonStyle, .sendEmailButton, .savedtypesChangesButtonMobile, .savedtypesChangesButtonDesktop, .sendBugButton{
  margin-top: 0;
  padding: 0.5em;
  font-size: 1em;
  font-family: 'Nunito';
  font-weight: 500;
  color: rgb(255,255,255);
  white-space: nowrap;
  cursor: pointer;
  background-color: rgb(0, 0, 0);
  border-radius: 1em;
}.sendEmailButton, .sendBugButton, .savedtypesChangesButtonMobile, .savedtypesChangesButtonDesktop{
  width: fit-content;
}.savedtypesChangesButtonMobile, .savedtypesChangesButtonDesktop{
  position: absolute;
}.savedtypesChangesButtonMobile{
  left: 50%;
  transform: translateX(-50%);
}.sendBugButton{
  margin-top: 1em;
}

.textButtonStyle{
  margin-left: auto;
  margin-right: auto;
}

.textStyle{
  font-size: 1em;
  max-width: 30em;
  font-family: 'Nunito';
  font-weight: 500;
  color: rgb(44 ,44 ,44)
}

.hiddenInput{
  display: none;
}

.editProfilePictureText{
  margin-left: auto;
  margin-right: auto;
  color: rgb(44, 44, 44);
  font-family: 'Nunito';
  font-weight: 500;
  cursor: pointer;
}

.photoGuidancePositioning{

}.textTitle, .categoryTextTitle{
  font-size: 1.1em;
  font-family: 'Nunito';
  font-weight: 750;
  color: rgb(44, 44, 44);
}.categoryTextTitle{
  font-size: 1.5em;
  margin-top: -0.5em;
}.simpleTextDesktop, .simpleTextMobile{
  margin-top: -0.8em;
  margin-bottom: 1.5em;
  font-size: 1em;
  font-family: 'Nunito';
  font-weight: 400;
  color: rgb(100, 100, 100);
}.simpleTextMobile{
  font-size: 0.9em;
}

.emailSentImage{
  margin-left:50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
}

.countriesButton, .currentlySelectedCountry, .typesOfTasksButton, .selectedTypeButton, .selectCountryButton{
  margin-left: auto;
  margin-right: auto;
  font-size: 1em;
  font-family: 'Nunito';
  font-weight: 600;
  text-align: center;
  width: 70%;
  max-width: 20em;
  padding: 0.5em;
  color: rgb(44, 44, 44);
  height: fit-content;
  border: 1px solid rgb(44, 44, 44);
  border-radius: 8px;
  background-color: white;
  user-select: none;
}.currentlySelectedCountry, .selectedTypeButton{
  background-color: rgb(66, 110, 207);
  border: 1px solid rgb(66, 110, 207);
  color: rgb(255, 255, 255);
  margin-bottom: 0;
}.countriesButton, .typesOfTasksButton, .selectedTypeButton, .selectCountryButton{
  cursor: pointer;
}.typesOfTasksButton, .selectedTypeButton{
  width: fit-content;
  margin: 0.5em;
}.selectCountryButton{
  width: fit-content;
  margin: 0;
}

.typesOfTasksContainer{
  display: flex;
  flex-wrap: wrap;
  align-items: left;
  margin-top: 4em;
}

.savedMessagesMessageDesktop, .savedMessagesMessageMobile{
  position: absolute;
  font-size: 1.1em;
  font-family: 'Nunito';
  font-weight: 650;
  color: rgb(50, 85, 160);
  white-space: nowrap;
}.savedMessagesMessageMobile{
  left: 50%;
  transform: translateX(-50%)
}

.reportTextAreaDesktop, .reportTextAreaMobile{
  /* max-width: 25em; */
  height: 10em;
  font-family: 'Nunito';
  color: rgb(44 ,44 ,44);
  background-color: white;
  border-radius: 4px;
}.reportTextAreaDesktop{
  max-width: 25em;
}

.loadingIcon{
  margin-top: 1em; 
  margin-left: auto;
  margin-right: auto;
  width: 30px;
  height: 30px;
  animation: rotate 2s linear infinite; /* Adjust the duration and timing function as needed */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.measurementOptionContainer{
  display: flex;
  flex-direction: column;
  width: 12em;
}.measurementOption{
  margin: 0.5em;
  margin-left: 0;
  padding: 0.5em;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}.unselectedMeasurement, .selectedMeasurement{
  width: 10px;
  height: 10px;
  margin-left: 0.5em;
  border: 2px solid rgb(160, 160, 160);
  border-radius: 50%;
}.selectedMeasurement{
  width: 14px;
  height: 14px;
  border: none;
  background-color: rgb(66, 110, 207);
}.measurementOption p{
  cursor: pointer;
  font-size: 1em;
  font-family: 'Nunito';
  font-weight: 600;
  color: rgb(44, 44, 44);
  margin: 0;
}

  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}