.mobileContainer{
}

.desktopContainer{
    position: relative
}

.clearButton {
    cursor: pointer;
    padding-left: 1em;
    padding-right: 1em;
    width: 15px;
    height: 15px;
    cursor: pointer;
    border-left: 1px solid rgb(211, 211, 211);
}

.desktopOptionsContainer, .mobileOptionsContainer{
    position: absolute;
    margin-top: -1.5em;
    right: 0em;
    height: 20em;
    max-height: 80vh;
    overflow: scroll;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: white;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 9995;
}.mobileOptionsContainer{
    right: 2em;
}.desktopOptionsContainer{
    margin-top: 3.5em;
}

.option, .showMorethanText{
    margin: 0;
    padding-top:1em;
    padding-bottom:1em;
    padding-left: 1em;
    padding-right: 1em;
    font-size: 0.9em;
    font-family: 'Nunito';
    font-weight: bold;
    color: #444444;
    cursor: pointer;
}.option:hover{
    background-color: rgb(236, 236, 236);
    border-radius: 4px;
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}