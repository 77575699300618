
.alternativeLocations{
    margin-top: 0;
    font-size: 1em;
    font-family: 'Nunito';
    font-weight: 500;
  }
  .alterlativeLocationsStyle{
    position: fixed;
    padding: 1em;
    width: 100%;
    max-width: 20em;
    top: 31%;
    left: 50%;
    transform: translate(-50%);
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 6px rgb(0, 0, 0, 0.6);
    border-radius: 4px;
    z-index: 9999;
  }
  
  .alterlativeLocationsText{
    font-size: 1em;
    font-family: 'Nunito';
    font-weight: 500;
    color: rgb( 44, 44, 44);
    cursor: pointer;
  }
  
  .locationIcon{
    width: 18px;
    height: 18px;
    margin-right: 0.2em;
  }
  
  .backgroundAlternativeLocations{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb( 0, 0, 0, 0 );
    z-index: 9998;
  }

  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
  }