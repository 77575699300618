.container{
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

  .connectConfirmationButtons{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .connectConfirmationButton, .notNowButton{
    width: 70%;
    max-width: 15em;
    height: 2em;
    margin-top: 3em;
    margin-left: 0em;
    padding: 1.5em 3em;
    color: rgb(245, 245, 245);
    background-color: rgb(50, 85, 160);
    font-size: 1em;
    font-family: 'Nunito';
    font-weight: 600;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }.connectConfirmationButtons button:last-child{
    width: fit-content;
    color: rgb(100, 100, 100);
    background-color: rgba(217, 14, 14, 0);
    white-space: nowrap;
  }.confirmationButtonDisabled{
    color: rgb(245, 245, 245);
    background-color: rgb(128, 128, 128);
  }

  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
  }