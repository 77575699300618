.errorTitle, .errorText, .errorTextMobile, .alternativeRecommendation {
    font-size: 1.2em;
    font-family: 'Nunito';
}.alternativeRecommendation{
    font-size: 1.5em;
    font-weight: 600;
}.errorText, .errorTextMobile{
    font-size: 1.05em;
} .errorTextMobile{
    margin-left: 1em;
}

.resolveSteps {
    font-family: 'Nunito';
    color: rgb(44, 44, 44);
    margin: 0;
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
  }
