.mobileTaskBody{
  position: relative;
  margin-top: 0;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 12px;
  border: 1px solid rgb(215, 215, 215);
  border-top: none;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 4px 4px 4px -4px rgb(175, 175, 175), 4px 4px 4px -4px rgb(175, 175, 175), -4px 4px 4px -4px rgb(175, 175, 175);
  -moz-box-shadow:    4px 4px 4px -4px rgb(175, 175, 175), 4px 4px 4px -4px rgb(175, 175, 175), -4px 4px 4px -4px rgb(175, 175, 175);
  box-shadow:         4px 4px 4px -4px rgb(175, 175, 175), 4px 4px 4px -4px rgb(175, 175, 175), -4px 4px 4px -4px rgb(175, 175, 175);
  overflow: hidden;
}.desktopTaskBody{
  position: relative;
  margin-top: 0;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(175, 175, 175, 1);
  overflow: hidden;
}
.expansionButton{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.taskBodyInitial{
  max-height: 80em;
}

.taskBodyUnbanned{
  animation: taskBodyUnbanned 0.3s ease-in-out;
  animation-fill-mode: forwards;
}  
.taskBodyBanned{
  animation: taskBodyBanned 0.3s ease-in-out;
  animation-fill-mode: forwards;
}
@keyframes taskBodyUnbanned {
  from {
    max-height:3em;
  }
  to {
    max-height:80em;
  }
}@keyframes taskBodyBanned {
  from {
    max-height: 80em;
  }
  to {
    max-height: 3em;
  }
}

.bannedListing{
  margin-bottom: 0em;
  display: flex;
  align-items: center;
}.bannedListing p{
  margin-left: 1em;
  font-size: 1em;
  font-weight: bold;
  font-family: 'Nunito';
}.bannedListing button{
  margin-left: auto;
  margin-right: 1em;
  padding: 5px 20px;
  font-size: 1.1em;
  text-align: center;
  font-weight: normal;
  font-family: 'Nunito';
  color: white;
  background-color: rgb(50,85,160);
  border: none;
  border-radius: 6px;
  user-select: none;
  cursor: pointer;
}

.header{
    display:flex;
}

.profilePicture{
    width: 40px;
    height: 40px;
    margin-right: 0.5em;
    border-radius: 50%;
}

.title{
  margin: auto;
  margin-left: 0;
  margin-right: 1.4em;
  align-items: center;
  font-size: 1.3em;
  font-family: 'Nunito';
  font-weight: 650;
  color:rgb(44, 44, 44);
  user-select: none;
}

.details {
    display: flex;
    flex-wrap: wrap;
  }

.applyButton{
  position: relative;
  width: 100%;
  max-width: 18em;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;
  font-size: 1.1em;
  text-align: center;
  font-weight: normal;
  font-family: 'Nunito';
  font-weight: 650;
  color: white;
  background-color: rgb(50,85,160);
  user-select: none;
  border-radius: 6px;
  border:none;
  cursor: pointer;
  z-index: 1;
}.appliedButton{
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;
  font-size: 1.1em;
  text-align: center;
  font-weight: normal;
  font-family: 'Nunito';
  color: rgb(50, 85, 160);
  font-weight: bold;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border:none;
}

.extraInformation{
    padding: 5px;
    font-size: 1em;
    color:rgb(44, 44, 44);
    border-radius: 12px;
    font-family: 'Nunito';
    user-select: none;
}

.detailContainer, .locationContainer{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  margin-top: 1em;
  margin-right: 1em;
  padding: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  user-select: none;
}.locationContainer{
  padding-left: 0;
  box-shadow: none;
}.detailText, .timeDetailText{
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 0.9em;
  font-weight: 650;
  font-family: 'Nunito';
  color:rgb(44, 44, 44);
  white-space: nowrap;
}.timeDetailText{
  margin-left: 1em;
  font-weight: 300;
}

.descriptionLine{
  margin-left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 1px;
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: rgb(230, 230, 230);
}

.descriptionContainer {
    display: flex;
    flex-direction: column;
    font-size: 1.05em;
    color: rgb(44, 44, 44);
    margin: auto;
    margin-top:0em;
    padding: 0em 0.5em;
    white-space: normal;
    font-family: 'Nunito';
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }

  .initialDescriptionHeight{
    max-height: 0em;
  }.initialDescriptionExpandedHeight{
    max-height: 80em;
  }

  .descriptionExpanded{
    animation: descriptionExpanded 0.5s ease;
    animation-fill-mode: forwards;
  }  
  .descriptionCollapsed{
    animation: descriptionCollapsed 0.5s ease;
    animation-fill-mode: forwards;
  }
  @keyframes descriptionExpanded {
    from {
      max-height:0em;
    }
    to {
      max-height:80em;
    }
  }@keyframes descriptionCollapsed {
    from {
      max-height: 80em;
    }
    to {
      max-height: 0em;
    }
  }

  .viewDescription{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1em;
  }.viewDescription :first-child{
    margin:0;
    padding:0;
    font-size: 0.9em;
    font-weight: bold;
    font-family: 'Nunito';
    color: rgb(44, 44, 44);
    cursor:pointer;
  }

.dateBookmarkContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-top: 1em;
}

.desiredDate{
  margin:0;
  color:rgb(110, 110, 110);
  font-family: 'Nunito';
  font-size: 0.85em;
  font-weight: 600;
  user-select: none;
}

.hidden{
  display: none;
}
.moreContainer{
  display: flex;
  flex-direction: column;
  position: absolute;
  padding:0 1em;
  top: 1em;
  right: 1em;
  width: fit-content;
  height: fit-content;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  z-index: 9994;
}.moreContainer{
  color: rgb(44, 44, 44);
  font-size: 1em;
  font-family: 'Nunito';
}.optionContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 2em;
  cursor: pointer;
}

.threeDots{
  position: absolute;
  top: 0em;
  right: 1em;
  width: 23px;
  height: 23px;
  margin-top: 1em;
  cursor: pointer;
}

.shareIcon{
  right: 1em;
  width: 23px;
  height: 23px;
  margin-right: 0.5em;
}

.banIcon{
  width: 23px;
  height: 23px;
  margin-right: 0.5em;
  cursor: pointer;
}

.filterIcon{
  width: 15px;
  height: 15px;
  margin-right: 0.2em;
}

.calendar{
  width: 15px;
  height: 15px;
  margin-right: 0.5em;
  margin-right: 0.5em;
}
.bookmark{
  position: absolute;
  top: 0;
  right: 0em;
  width: 23px;
  height: 25px;
  text-align: center;
  cursor: pointer;
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}