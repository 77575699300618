.title{
  margin-top: -0.5em;
  font-size: 1.2em;
  font-family: 'Nunito';
  font-weight: 500;
}

.container, .otherProfiles{
  position: fixed;
  top:0em;
  left:0em;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  overflow: scroll;
}.lowContainerIndex{
  z-index: 998;
}.highContainerIndex{
  z-index: 999;
}

.containerAbsolute, .otherProfiles{
  position: absolute;
}

.desktopContainer{

}

.applicantsBackgroundStyle, .profileBackgroundStyle{
  position: fixed;
  top: 3.5em;
  left: 0em;
  width: 100%;
  height: 5.5em;
  background-color: rgb(250, 250, 250);
  z-index: 1;
}.applicantsBackgroundStyle{
  height: 4em;
  top: 0em;
}

.mobileProfileContainer{
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 1em;
  padding-bottom: 0.5em;
}

.profiledetails{
  position: relative;
  display:flex;
  align-items: center;
  z-index: 5;
}

.candidateDetails, .details ,.detailsDesktop {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
}.details{
  margin-top: 1.5em;
}

.nameSurname {
  font-size: 1.2em;
  font-weight: bold;
  font-family: 'Nunito';
  margin-top: 1em;
  margin-bottom: 0.2em;
  color: rgb(83, 83, 83);
}

.star img{
    width: 20px;
    height: 20px;
    margin: 0px 0.05em;
}

.ratingsContainer {
    display: flex;
    align-items: center; /* Align items vertically */
    font-family: 'Nunito';
    color: rgb(44, 44, 44);
}

.ratingsCounter{
    margin: 0;
    margin-left: 0.5em;
    padding: 0;
    align-items:center;
    text-align: center;
}

.reviewsTitle, .contactOptions{
    text-align: center;
    margin-top: 2em;
    margin-bottom: 1em;
    font-size: 1.3em;
    color:rgb(128, 128, 128);
    font-family: 'Nunito';
  }.reviewsTitle{
    margin-top: 2.5em;
}

.candidateProfilePicture, .profilePicture, .profilePictureDesktop{
  width: 110px;
  height: 110px;
  margin-top: 1em;
  margin-left: 0em;
  border-radius: 50%;
}.candidateProfilePicture{
  width: 70px;
  height: 70px;
}.profilePicture{
  border: 5px solid white;
}

.frequentTasksTitle, .abandonStyle{
  margin-top: 2em;
  font-size: 1.2em;
  font-family: 'Nunito';
  font-weight: 450;
  color: rgb(17, 36, 62);
}.abandonStyle{
  position: absolute;
  top: -0.5em;
  right: 0;
  width: fit-content;
  font-size: 1em;
  font-weight: 500;
  color: rgb(189, 34, 31);
  cursor: pointer;
  z-index: 999;
}

.topTypesOfTasks{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: left;
  margin-top: 0em;
}

.typeoftask{
    margin: 0;
    margin-left: 0em;
    margin-right: 0.5em;
    padding: 5px 5px;
    color: rgb(17, 36, 62);
    font-size: 1em;
    font-family: 'Nunito';
    font-weight: 650;
}

.connectButton{
    width: 70%;
    max-width: 15em;
    height: 2em;
    margin-top: 3em;
    margin-bottom: 0em;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5em 0em;
    font-size: 1em;
    text-align: center;
    font-family: 'Nunito';
    font-weight: 550;
    color: white;
    background-color: rgb(50, 85, 160);
    user-select: none;
    border-radius: 8px;
    border:none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
/*----------Desktop----------*/
.desktopProfileContainer{
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

}

.ratings, .desktopRatings{
  margin-top: 0.5em;
}.desktopRatings{
  margin-top: 0.5em;
  width: 100%;
  max-width: 35em;
  margin-left: auto;
  margin-right: auto;
}


.slideRight {
    animation: slideRight 0.3s ease-out;
    animation-fill-mode: forwards;
    transform: translateX(100%);
  }
  
  .slideLeft {
    animation: slideLeft 0.3s ease-out;
    animation-fill-mode: forwards;
    transform: translateX(-100%);
  }
  
  @keyframes slideRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideLeft {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }

  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
  }