.blocker{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.container{
  position: fixed;
  top:0em;
  left:0;
  /* padding-top: 3em; */
  width: 100%;
  height: 100%;
  /* height: calc(100% - 3em); */
  background-color: rgb(255, 255, 255);
  overflow: scroll;
}.lowContainerIndex{
  z-index: 998;
}.highContainerIndex{
  z-index: 999;
}

.containerAbsolute{
  position: absolute;
}

.background{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 9995;
}
.desktopContainer{
  position: fixed;
  top:4em;
  right: 1em;
  padding-top:3em;
  width: 25em;
  height: 60%;
  padding: 0em;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 6px rgb(0, 0, 0, .5);
  z-index: 9999;
  border-radius: 8px;
  overflow: scroll;
}

.slideRight {
    animation: slideRight 0.4s ease-out;
    animation-fill-mode: forwards;
    transform: translateX(100%);
  }
  
  .slideLeft {
    animation: slideLeft 0.4s ease-out;
    animation-fill-mode: forwards;
    transform: translateX(-100%);
  }
  
  @keyframes slideRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideLeft {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }


  .componentTitle{
    font-family: 'Nunito';
    font-weight: 800;
    font-size: 1.5em;
    color: rgb(44, 44, 44);
    margin:0.5em;
  }
  .profilePicture{
    width: 60px;
    height: 60px;
    /* margin-top: 0.5em; */
    margin-right: 0.5em;
    border-radius: 50%;
    cursor: pointer;
}

.timeElapsedFromNotification, .title, .text{
  font-family: 'Nunito';
}.title{
  font-size: 1em;
  font-weight: 650;
  color: rgb(44, 44, 44);
  margin: 0;
}.text{
  font-size: 1em;
  font-weight: 500;
  color: rgb(44, 44, 44);
}.timeElapsedFromNotification{
  font-size: 0.9em;
  font-weight: 650;
  color: rgb(50, 85, 160);
  margin: 0;
  margin-top: 0.5em;
}

.notificationContainer{
  padding: 0.5em;
}

.confirmationButton{
  margin-left: auto;
  cursor: pointer;
  width: fit-content;
  padding: 0.7em 1.5em;
  font-size: 1em;
  font-family: 'Nunito';
  font-weight: 650;
  color: whitesmoke;
  background-color: rgb(50, 85, 160);
  border: none;
  border-radius: 4px;
}

.isSelected{
  background-color: rgb(245, 245, 245);
  border-radius: 8px;
}

.headContainer{
  display:flex;
  flex-direction: row;
  /* align-items: center; */
  margin-bottom: 0;
  cursor: pointer;
}

.listingTitle{
  font-size: 0.95em;
  font-family: 'Nunito';
  font-weight: 400;
  color: rgb(134, 134, 134);
  margin: 0;
  margin-left: auto;
  text-align: left;
  font-style: italic;
}

.newNotificationIcon{
  font-size: 1.5em;
  color: rgb(50, 85 ,160);
  padding-left: 0.5em;
  margin-left: auto;
}

.noNotificationsMessage{
  text-align: center;
  text-align: center;
  color: rgb(180, 180, 180);
  font-size: 1.5em;
  font-family: 'Nunito';
  font-weight: 400;
}

.timeAndButtonPosition{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loadingIcon{
  position: absolute;
  right: 10em;
  width: 25px;
  height: 25px;
  animation: rotate 2s linear infinite; /* Adjust the duration and timing function as needed */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}