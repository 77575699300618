.mobileContainer{
  margin-left: 1em;
}

.desktopContainer{

}

.filterContainer{
  display:flex;
  justify-content: center;
  padding-top: 1.4em;
  position: absolute;
  margin-left: 0em;
}
.overflownFilterContainer {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 1.4em;
  position: absolute;
  scroll-snap-type: x mandatory;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  /* mask-image: linear-gradient(to right, transparent 0%, black 1%, black 99%, transparent 100%); */
} 

  .overflownFilterContainer::-webkit-scrollbar {
    width: 6px;
    display: none;
  }
  
  .overflownFilterContainer::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
  
  .overflownFilterContainer::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .filterWrapper{
    margin-right: 10px;
    margin-bottom: 0em;
    display: flex;
    align-items: center; /* Align items vertically */
  }
  

.filterElement, .filterLocation, .filterDateElement{
    margin: 0;
    margin-bottom: 0.5em;
    height: 3.2em;
    padding: 0px 0px;
    padding-left: 15px;
    font-size: 0.9em;
    font-weight: bold;
    color: rgba(44, 44, 44);
    text-align: center;
    align-self: center;
    border-radius: 8px;
    border: 1px solid rgb(236, 236, 236);
    background-color: rgb(236, 236, 236);
    cursor: pointer;
    user-select: none;
    scroll-snap-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex; /* Added */
    align-items: center; /* Added */
}
.filterDateElement{
  margin: 0;
  padding: 0;
  outline: none;
  font-family: 'Nunito';
  font-size: 0.9em;
  color: rgb(44, 44, 44);
  -webkit-appearance: none; /* Remove default styling on iOS */
  -moz-appearance: none; /* Remove default styling on Firefox */
  appearance: none; /* Remove default styling on other browsers */
  /* Add your desired styles here to match the rest of the inputs */
}
.filterElementText {
  font-family: 'Nunito';
  font-size: 0.9em;
  text-align: center  ;
  color: rgb(44, 44, 44);
}

.x{
  align-self: center;
  width:13px;
  margin-left: 0px; /* Added margin between the text and the "x" button */
  padding:0px 15px;
  padding-left: 8px;
}

.selectedFilter{
  background-color: rgb(115, 115, 115);
}.selectedFilterText{
  color: rgb(255, 255, 255);
}

@media (max-width:678px) {
  .overflownFilterContainer{
  }
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}