.mobileListedTasksLayout{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 1em;
    padding-bottom: 2em;
}
.desktopListedTasksLayout{
    display: flex;
    flex-direction: column;
}

.hidden{
    display: none;
}

.noTasksFoundContainer{
    width: 100%;
}

.noTasksMessage{
    margin-top: 0em;
    margin-left: 0em;
    text-align: left;
    color: rgb(44, 44, 44);
    font-size: 1.1em;
    font-family: 'Nunito';
    font-weight: 400;
}

.noTasksIcon{
    position: absolute;
    width: 200px;
    left: 50%;
    transform: translateX(-50%);
}

.noResultsReturned, .noResultsReturnedMobile{
    position: absolute;
    bottom: 2em;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    color: rgb(100, 100, 100);
    font-size: 1.3em;
    font-family: 'Nunito';
    font-weight: 400;
}.noResultsReturnedMobile{
    bottom: 0em;
}

.noLocationExists{
    margin: 0;
    color: rgb(100, 100, 100);
    font-size: 1.3em;
    font-family: 'Nunito';
    font-weight: 400;
}

.loadingIconStyleMobile{
    position: absolute;
    bottom: 0em;
    width: 30px;
    height: 30px;
    left: calc(50% - 15px);
    transform: translateX(-50%);
    animation: rotate 2s linear infinite; /* Adjust the duration and timing function as needed */
}

.loadingIconStyleDesktop{
    position: absolute;
    bottom: 2em;
    width: 30px;
    height: 30px;
    left: calc(50% - 15px);
    transform: translateX(-50%);
    animation: rotate 2s linear infinite; /* Adjust the duration and timing function as needed */
}

 @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

  .alternativeLocations{
    margin-top: 0;
    font-size: 1em;
    font-family: 'Nunito';
    font-weight: 500;
  }
  
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}