.starContainer{
    display: flex;
}

.star{
    width:15px;
    height:15px;
    margin-right: 0.1em;
}

.reviewStarContainer{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    margin-left:50%;
    width: 13em;
    transform: translateX(-50%);
}.reviewStar{
    width: 35px;
    height: 35px;
    margin-right: 0em;
    cursor: pointer;
}