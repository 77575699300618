.mobileContainer{
}

.desktopContainer{
    margin-top: 3em;
    width: 38em;
    margin-right: 1em;
    margin-left: 1em;
}

.desktopExtraContainer{
    position: fixed;
    top: 3em;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: white;
}

.accountSettingsWindowBackground{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0);
    z-index: 9997;
  }

.hidden{
    display: none;
}

.imageCropbackground{
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9998;
}

.imageCropPositioning{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 20em;
    height: 20em;
    z-index: 9999;
}

.cropButton, .cancelCropButton{
    position: fixed;
    bottom: -3em;
    padding: 0.5em 0.7em;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.2em;
    width: 100%;
    max-width: 20em;
    border-radius: 50px;
    border: 0;
    cursor: pointer;
    font-weight: 400;
    font-family: 'Nunito';
    color: white;
    background-color: rgb(0, 0, 0);
}.cancelCropButton{
    bottom: -5.5em;
    background-color: rgb(176, 176, 176);
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
  }