.mobileContainer{
  position: absolute;
  top: 12em;
  left: 1em;
  right: 1em;
  margin-top: 0em;
  padding:1em 0em;
  border-top: 1px solid rgb(230, 230, 230);
}

.desktopContainer{
  position: absolute;
  top: 10em;
  left: 1em;
  right: 1em;
  margin-top: 0em;
  border-top: 1px solid rgb(230, 230, 230);
  padding:1em 5em;
  padding-left: 10em;
}
.title{
  margin: auto;
  margin-left: 0;
  align-items: center;
  font-size: 1.7em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  color:rgb(44, 44, 44);
  user-select: none;
}

.details {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0.5em;
}

.detailContainer, .locationContainer{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  margin-right: 1em;
  padding: 5px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  user-select: none;
}.locationContainer{
  box-shadow: none;
  margin-right: 0em;
  margin-bottom: 1em;
}

.detailText{
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color:rgb(44, 44, 44);
  white-space: nowrap;
}.locationDetail{
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color:rgb(44, 44, 44);
  white-space: nowrap;
}

.filterIcon{
  width: 15px;
  height: 15px;
  margin-right: 0.2em;
}

.descriptionContainer{
  display: flex;
  flex-direction: column;
  max-width: 78.5em;
}.mobileDescriptionContainer{
  border-top: 1px solid rgb(230, 230, 230);
  max-width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  margin-bottom: 0em;
}
.descriptionDesktopVersion{
  width: fit-content;
  max-width: 35em;
}
.description{
  display: flex;
  flex-direction: column;
  font-size: 1.05em;
  color: rgb(44, 44, 44);
  margin: auto;
  margin-top: 1em;
  white-space: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  user-select: none;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.actionButtonsContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5em;
}

.applyButton{
  font-size: 1.1em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: whitesmoke;
  margin: 1em 0;
  padding: 0.8em 2.5em;
  width: fit-content;
  font-weight: bold;
  background-color: rgb(50,85,160);
  border-radius: 6px;
  cursor: pointer;
}
.bookmark{
  width: 23px;
  height: 25px;
  margin-left: 1.5em;
  cursor: pointer;
}.share{
  width: 25px;
  height: 25px;
  margin-left: 0.5em;
  cursor: pointer;
}

@media(max-width: 850px){
  .desktopContainer{
    position: absolute;
    left: 1em;
    right: 1em;
    margin-top: 0em;
    padding: 1em 0em;
    overflow-y: auto;
  }

  .descriptionDesktopVersion{
    width: fit-content;
    max-width: 35em;
  }
}

.noTaskFoundContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageNotFoundTitle{
  font-size: 1.5em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  color: rgb(44, 44, 44);
}

.pageNotFoundDescription{
  font-size: 1em;
  width: 23em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: rgb(44, 44, 44);
}