.container{
    position: absolute;
    left: 0em;
    right: 0em;
    margin-top: 5em;
}

.test{
    font-size: 1.1em;
    font-family: 'Nunito';
    font-weight: 400;
    background-color:  whitesmoke;
    color: rgb(100, 100, 100);
    padding: 1em 0;
    margin: 0em;
    padding: 2em 0.5em;
    margin-top: -1.5em;
    margin-bottom: 1.5em;
}

.popularServicesMessage, .typeStyle, .recentSearchedServicesMessage, .categoriesMessage{
    color: rgb(17, 36, 62);
    font-family: 'Nunito';
    font-size: 1.8em;
    font-weight: 800;
    margin: 0;
    margin-left: 0.5em;
    margin-bottom: 0.5em;
}.popularServicesMessage{
    margin-top: 0em;
    margin-bottom: 0.5em;
}.recentSearchedServicesMessage{
    color: rgb(100, 100, 100);
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom:0;
    margin-top: 1em;
    padding-top: 1em;
}
.categoriesMessage{
    margin: 0;
    margin-bottom: 1em;
    font-size: 1.1em;
    font-weight: 600;
}.categoriesMessageHomePage, .popularTitle{
    font-family: 'Nunito';
    margin: 0;
    margin-top: 2em;
    margin-bottom: 1em;
    font-size: 1em;
    font-weight: 600;
}.popularTitle{
    margin-top: 0.5em;
    font-size: 1.1em;
    font-weight: 800;
    color: rgb(17, 36, 62);
    white-space: nowrap;
}
.typesContainerStyle{
    margin-top: 2em;
}.typeStyle{
    font-size: 1em;
    font-weight: 400;
    color: rgb(17, 36, 62);
    margin: 0.5em;
}

.categoriesContainer, .categoriesContainerDesktop{
    position: relative;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: visible;
    padding-left: 0em;
    padding-right: 0.5em;
    margin: 0 0.5em;
    margin-bottom: 3.5em;
    border-radius: 8px;
}.categoriesContainer{
    margin: 0;
    margin-bottom: 3em;
}
.suggestions{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0em 1em;
}
.suggestionsContainer{
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    box-shadow: 0 0px 2px rgb(0, 0, 0, 0.3);
    border-radius: 4px;
    width: 12em;
    height: 8em;
    padding: 1em;
    margin: 0.5em 0;
    background-color: rgb(255, 255, 255);
}

.searchedSuggestions{
    display: flex;
    flex-direction: row;
    /* background-color: rgb(250, 250, 250); */
}

.searchedType{
    margin: 0;
    margin-bottom: 0.5em;
    text-align: left;
    font-size: 1em;
    color: rgb(133, 125, 125);
    font-family: 'Nunito';
}
.suggestedTask{
    font-weight: 500;
    color: rgb(79, 79, 79);
    box-shadow: 0 0px 2px rgb(0, 0, 0, 0.3);
    background-color: white;
    margin-right: 1em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    padding: 1em;
    width: 16em;
    height: 5em;
    border-radius: 4px;
}

.suggestion, .category{
    font-size: 1.2em;
    font-family: 'Nunito';
    text-align: center;
    padding: 0.5 0;
    margin:0.5em 0;
    cursor: pointer;
    color: rgb(90, 90, 90);
}.suggestion:hover{
    color: rgb(50, 85, 160);
    font-weight: bold;
}

.categoryHomepage, .popularCategories, .category{
    font-size: 1em;
    font-weight: 300;
    margin: 1em 0;
    color: rgb(44, 44, 44);
    background-color: rgb(255, 255, 255);
}.categoryHomepage, .popularCategories{
    width: fit-content;
    font-size: 0.9em;
    font-weight: 300;
    margin: 1em 0;
    color: rgb(100,100,100);
    cursor: pointer;
}.categoryHomepage{
    border: 0;
    margin:0.5em;
    margin-left:0em;
    padding: 0;
}.popularCategories{
    font-family: 'Nunito';
    font-weight: 400;
    color: rgb(60, 60, 80);
    cursor: pointer;
    background-color: (0, 0, 0, 0);
    border: 0;
    margin: 0.5em;
    margin-left: -0.5em;
    padding-left: 0.5em;
}.typesStyle{
    display: flex;
    flex-direction: column;
    margin-right: 3em;
    white-space: nowrap;
}

.topCategoriesContainer {
    position: fixed;
    top: 9em;
    bottom: 1em;
    left: calc(50% - 27.5em);
    transform: translateX(-50%);
    overflow-y: auto;
    max-height: 100em;
}


.descriptionMobileVersion{
    width: 50%;
    background-color: rgb(0, 0, 0);
}.typesInnerContainerDesktop, .typesInnerContainerMobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15%;
}.typesInnerContainerMobile{
    margin-left: 0em;
    width: 38%;
}

.featuredCategoriesDesktop{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 1em;
    margin-left: 0;
    margin-bottom:0em;
    background-color: white;
    margin-bottom: 3em;
    padding:0;
    overflow: hidden;
}
.featuredCategory{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 8em;
    padding: 0em 0;
    padding-bottom: 0;
    margin-bottom: 2em;
    flex-basis: 20%;
}.featuredCategory img{
    position: absolute;
    width: 35px;
    height: 35px;
    transition: width 0.3s, height 0.3s;
}.featuredCategory:hover img{
    width: 40px;
    height: 40px;
}.featuredCategory p{
    padding-top: 2em;
    font-size: 1em;
    font-family: 'Nunito';
    color: rgb(80, 80, 80);
    cursor: pointer;
    font-weight: 500;
}

.moreInfoContainer{
    margin-bottom: 1em;
}
.moreInfoText{
    font-size: 1.1em;
    font-family: 'Nunito';
    font-weight: 800; 
    border-top: 1px solid rgb(222, 222, 222);
    color: rgb(44, 44, 44);
    margin:0;
    padding: 1em;
}

.footerContainerDesktop{
    display: flex;
    flex-wrap:wrap;
    margin-bottom: 4em;
    max-width: 78.5em;
    margin-left: auto;
    margin-right: auto;
}

.moreInfoContainerDesktop, .footerContainerDesktop{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
}.moreInfoContainerDesktop p, .footerContainerDesktop p, .moreInfoContainerDesktop a, .footerContainerDesktop a{
    margin-right: 1.5em;
    font-size: 0.9em;
    font-family: 'Nunito';
    font-weight: 500;
    color: rgb(44, 44, 44);
}.moreInfoContainerDesktop a, .footerContainerDesktop a{
    text-decoration: none;
}.moreInfoContainerDesktop p:hover, .footerContainerDesktop p:hover, .moreInfoContainerDesktop a:hover, .footerContainerDesktop a:hover{
    text-decoration: underline;
    cursor: pointer;
}

.divideLine{
    height: 1px;
    background-color: rgb(213, 213, 213);
}

.footerContainer{
    position: absolute;
    text-align: left;
    font-size: 0.9em;
    font-family: 'Nunito';
    font-weight: 500;
    width: fit-content;
    padding-top: 0.5em;
    left: 50%;
    transform: translateX(-50%);
    right: 0em;
    color: rgb(44, 44, 44);
    z-index: 9995;
}.selectedFooterButton:hover{
    text-decoration: underline;
    cursor: pointer;
}

.recentlyViewedTasksContainer{
}

.listingTitle, .listingLocation{
    margin:0;
    font-size: 1.2em;
    font-family: 'Nunito';
    font-weight: 500;
    color: rgb(44, 44, 44);
}.listingLocation{
    margin-top: 1em;
    font-size: 1em;
    font-family: 'Nunito';
    font-weight: 400;
    color:rgb(100, 100, 100);
}.listingPrice{
    margin:0;
    font-size: 1.1em;
    font-family: 'Nunito';
    font-weight: 500;
    color:rgb(44, 44, 44);
    margin-right: 1.5em;
}.listingType{
    font-family: 'Nunito';
    font-weight: 500;
    color:rgb(122, 122, 122);
    margin-top: 0;
    margin-left: 0.5em;
}

.appMessageInnerContainer{
    max-width: 78.5em;
    margin-left: auto;
    margin-right: auto;
}

.appMessageContainer{
    position: absolute;
    width: 100%;
    left: 0;
    background-color: rgb(247, 249, 252);
    z-index: 1;
}
.innerAppMessageContainer, .innerAppMessageContainerDesktop{
    position: relative;
    padding: 4em 1em;
    margin-bottom: 2.5em;
    max-width: 78.5em;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
}.innerAppMessageContainerDesktop{
    margin-bottom: 2.5em;
    padding: 3em 1em;
}

.subtitleContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tickIMGStyle{
    width: 23px;
    height: 23px;
    margin-right: 0.5em;
}

.appMessageTitle{
    margin: 0;
    margin-bottom: 0.6em;
    font-size: 1.8em;
    font-family: 'Nunito';
    font-weight: 750;
    color: rgb(17, 36, 62);
}
.appMessageSubtitle, .appMessageText{
    margin:0;
    color: rgb(44, 44, 90);
    font-size: 1.1em;
    font-family: 'Nunito';
}.appMessageSubtitle{
    font-size: 1em;
    font-weight: 800;
}.appMessageText{
    max-width: 30em;
    color: rgb(60, 60, 100);
    margin-left: 0em;
    margin-top: 0.5em;
    margin-bottom: 1.5em;
    font-weight: 400;
    font-size: 1.05em;
}

.trendingTasksContainerDesktop{
    position: relative;
    padding-left: 3em;
    padding-right: 3em;
    margin-bottom: 0em;
}.trendingTasksContainer{
    position: relative;
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 0em;
    padding-left: 0em;
    padding-right: 0em;
}
.leftArrow{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top:50%;
    left: 1.5em;
    transform: translate(-50%, -50%);
    z-index: 999;
}.rightArrow{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    right: 0.25em;
    transform: translate(0%, -50%);
    z-index: 999;
}.typesArrowStyle {
    width: 18px;
    height: 18px;
    padding: 0.5em;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border: none;
    transition: box-shadow 0.3s ease; /* Add transition to the box-shadow property */
}

.typesArrowStyle:hover {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
}

.viewMoreButton {
    margin-top: -2em;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    width: fit-content;
    background-color: none;
    background-color: rgb(255, 255, 255);
}.viewMoreButton button{
    margin: 0em;
    margin-bottom: 3em;
    font-size: 1.1em;
    font-family: 'Nunito';
    padding: 0.5em;
    color: rgb(44, 44, 44);
    font-weight: 800;
    background-color: rgb(17, 36, 62, 0);
    border: none;
    user-select: none;
    outline: none;
    cursor: pointer;
}


ul {
    list-style: none;
  }
  
ul, li {
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 1300px) {
    .typesInnerContainerDesktop{
        width: 20%;
    }
}

@media screen and (max-width: 1200px) {
    .typesInnerContainerDesktop{
        width: 25%;
    }
}

@media screen and (max-width: 900px) {
    .typesInnerContainerDesktop{
        width: 33.3%;
    }
}

@media (max-width: 768px) {
    .featuredCategory {
      flex-basis: 33.3%;
    }
}

@media (max-width: 600px) {
    .featuredCategory {
        flex-basis: 50%;
    }
}

@media screen and (max-width: 550px) {
    .typesInnerContainerDesktop{
        width: 50%;
    }
}

@media screen and (max-width: 350px) {
    .typesInnerContainerDesktop{
        width: 100%;
    }
}

@media screen and (max-width: 1120px) {
    .topCategoriesContainer {
        position: fixed;
        top: 9em;
        left: 1em;
        transform: none;
    }
  }
  
  @media screen and (max-width: 1030px) {
    .topCategoriesContainer {
        display: none;
    }

    .featuredCategoriesDesktop{
        display: flex;
        flex-direction: row;
        margin-top: 1em;
        margin-left: 1em;
        margin-right: 1em;
        margin-bottom:0em;
        background-color: white;
        margin-bottom: 3em;
        overflow-x: auto;
    }
    .innerAppMessageContainerDesktop{
        padding: 3em 1em;
    }
  }

  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
  }