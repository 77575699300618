.container{
  position: fixed;
  top:0em;
  left:0;
  width: 100%;
  height:100%;
  background-color: rgb(255, 255, 255);
  overflow: scroll;
}.lowContainerIndex{
  z-index: 998;
}.highContainerIndex{
  z-index: 999;
}

.containerAbsolute{
  position: absolute;
}

.desktopContainer{
  margin-top: 2em;
}

.buttonsContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex: 1;
  margin-top: 0em;
}

.category{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin:0;
  margin-bottom: 1em;
  padding: 0.5em;
  width: fit-content;
  text-align: left;
  font-weight: normal;
  font-size: 1.2em;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
}.category p{
  margin:0;
  font-family: 'Nunito';
  font-weight: 65 0;
}

.taskSectionIsEmpty{
  text-align: center;
  color: rgb(180, 180, 180);
  font-size: 1.5em;
  font-family: 'Nunito';
  font-weight: 400;
}

.selectedCategory{
  color: rgb(50, 85, 160);;
  font-weight: bold;
}

.selectedButtonLine{
  width: 100%;
  height: 2px;
  background-color: rgb(50, 85, 160);
}

.mobileTasksLayout{
  display: flex;
  flex-direction: column;
  margin: 0 1em;
  padding-top: 1em;
}

.desktopTasksLayout{
  display: flex;
  flex-direction: column;
  margin-top: 1.6em;
}

.slideRight {
  animation: slideRight 0.4s ease-out;
  animation-fill-mode: forwards;
  transform: translateX(100%);
}

.slideLeft {
  animation: slideLeft 0.4s ease-out;
  animation-fill-mode: forwards;
  transform: translateX(-100%);
}

@keyframes slideRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}