.searchContainer{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 38em;
  height: 3.2em;
  margin-bottom: 0.5em;
  padding: 0em;
  text-align: left;
  font-size: 1em;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgb(220, 220, 220);
  /* border-bottom: 5px solid rgb(50, 85, 160); */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  outline: none;
  z-index: 3;
  }.searchContainerMobile{
    margin-top: 4em;
    max-width: calc(100% - 2em);
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  }.searchContainerMobileExtraBottomSpace{
    margin-top: 4em;
    max-width: calc(100% - 2em);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5.5em;
  }
  .searchContainerDesktop{
    margin-top: 1.6em;
  }

  .mobileSearch {
    text-align: left;
    font-size: 1em;
    color: #444444;
    font-family: 'Nunito';
    font-weight: 600;
    flex-grow: 1; /* Add this line to make the input stretch */
    margin: 0;
    padding: 0.7em;
    padding-left: 0.5em;
    width: 100%;
    border: none;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    outline: none;
  }

  .clearButton {
    position: absolute;
    right: 4em;
    cursor: pointer;
    padding-left: 1em;
    padding-right: 1em;
    width: 15px;
    height: 15px;
    cursor: pointer;
    border-left: 1px solid rgb(211, 211, 211);
  }

.desktopSearch {
  text-align: left;
  font-size: 1em;
  color: #444444;
  font-family: 'Nunito';
  font-weight: 600;
  flex-grow: 1; /* Add this line to make the input stretch */
  margin: 0;
  padding: 0.7em;
  width: 100%;
  border: none;
  border-radius: 8px;
  outline: none;
}

.goButton{
  position: relative;
  font-family: 'Nunito';
  width: 100%;
  max-width: calc(100% - 1.8em);
  height: 3em;
  margin-left: 0.9em;
  font-size: 1.1em;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  color: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  background-color: rgb(69, 116, 216);
  z-index: 2;
}

.goButtonDesktop{
  height: calc(100% - 1em);
  padding: 0 1em;
  margin-right: 0.5em;
  font-family: 'Nunito';
  font-size: 0.9em;
  font-weight: bold;
  border-radius: 6px;
  border: none;
  color: white;
}

.goButtonLightBlue{
  background-color: rgb(50, 85, 160);
  transition: background-color 0.3s ease;
}.goButtonNormalBlue{
  background-color: rgb(50, 85, 160 );
  transition: background-color 0.3s ease;
}

.desktopOptionsContainer{
  position: absolute;
  width: 100%;
  max-width: 38em;
  height: 14em;
  overflow: hidden;
  margin-top: -0.5em;
  padding-bottom: 0.5em;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(211, 211, 211);
  border-radius: 4px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  z-index: 9995;
}

.option{
  margin: 0;
  padding-top:1em;
  padding-bottom:1em;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 0.9em;
  font-family: 'Nunito';
  font-weight: bold;
  color: #444444;
  cursor: pointer;
}.option:hover{
  background-color: rgb(236, 236, 236);
  border-radius: 4px;
}.selectedOption{
  background-color: rgb(236, 236, 236);
  border-radius: 4px;
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}