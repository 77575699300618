.messagingSystemContainerCollapsed {
    position: fixed;
    display: flex;
    align-items: center;
    bottom: 1em;
    right: 1em;
    z-index: 9996;
    width: 5em;
    height: 3em;
    padding: 0 1.5em;
    background-color: rgb(50, 85, 160);
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 0 6px rgb(0, 0, 0, .5);
  }

.messagingSystemContainerExpanded {
    position: fixed;
    bottom: 0.5em;
    right: 0.5em;
    z-index: 9996;
    width: 22em;
    height: 30em;
    padding: 0 0em;
    border: 1px solid rgb(211,211,211);
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    background-color: rgb(255, 255, 255);
    transition: all 0.3s ease;
}

.messagesContainerCollapsed{
    font-size: 1em;
    font-family: 'Nunito';
    font-weight: 600;
    color: white;
    cursor: pointer;
    background-color: rgb(50, 85, 160);
}

.messagesContainerExpanded{
    position: absolute;
    top: -0.5em;
    right: 1em;
    font-size: 1em;
    text-align: right;
    font-family: 'Nunito';
    font-weight: 800;
    color: rgb(50, 85, 160);
}

.headerContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.exitButton{
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    width: 17px;
    height: 17px;
    cursor: pointer;
}
.profilesContainerMobile{
    display: flex;
    /* border-bottom: 1px solid black; */
    border-color: rgb(211,211,211);
    width: 100%;
    margin-top: -0.5em;
    margin-left: 0em;
    padding-left: 2.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    overflow-x: auto;
}
.profilesContainer{
    display: flex;
    margin-top: 2em;
    border-color: rgb(211,211,211);
    width: calc(100% + 4em);
    padding-bottom: 0.5em;
    overflow-x: auto;
    /* background-color: red; */
    /* mask-image: linear-gradient(to right, transparent 0%, black 5%, black 95%, transparent 100%); */
}

.profilePicture {
  padding: 0em;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s;
}

.selectedProfile {
  width: 50px;
  height: 50px;
}


.profileStyle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 55px; 
  height: 55px;
  margin: 5px;
  transition: all 0.2s;
}

.unreadMessages, .unreadMessagesDesktop{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -0.6em;
  right: 0.4em;
  width: 1.3em;
  height: 1.3em;
  background-color: rgb(255, 58, 58);
  color: rgb(255, 255, 255);
  font-size: 1em;
  font-family: 'Nunito';
  font-weight: 400;
  border-radius: 50%;
}.unreadMessagesDesktop{
  top: -1.5em;
  left: -0.5em;
  width: 1.6em;
  height: 1.6em;
}

.testCircle{
  border: 3px solid rgb(50, 85, 160);
}

.messagingContainer{

}

.noDiscussionMessageDesktop{
    font-size: 1.2em;
    font-family: 'Nunito';
    font-weight: 400;
    margin-top: 20%;
    padding: 1em;
    color: rgb(180, 180, 180);
}.noDiscussionMessageMobile{
  margin-left: 50%;
  width: 80%;
  transform: translateX(-50%);
  color: rgb(180, 180, 180);
  font-family: 'Nunito';
  font-weight: 400;
  margin-top: 2.5em;
  font-size: 1.5em;
}

.inputContainerMobile{
    display: flex;
    flex-direction: row;
    position: absolute;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    bottom: 0.5em;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
}

.inputContainer{
    display: flex;
    flex-direction: row;
    position: absolute;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
}

.textBarMobile{
    width: 85%;
    padding: 0.5em;
    margin-left: 0.5em;
    font-size: 1.2em;
    font-family: 'Nunito';
    color: rgb( 44, 44, 44 );
    border-radius: 12px;
    height: 4em;
}
.textBar{
    width: 80%;
    padding: 0.7em;
    margin-left: 0.5em;
    font-size: 1em;
    font-family: 'Nunito';
    color: rgb( 44, 44, 44 );
    border-radius: 8px;
    border: 1px solid rgb(211, 211, 211);
    word-wrap: break-word; /* Allow long words to be broken and wrap onto the next line */
    overflow-wrap: break-word; /* Alternative property for word wrapping */
    height: 2em;
  }

.sendMessageButtonMobile{
    color: white;
    margin-right: 0.5em;
    margin-left: 0.5em;
    background-color: rgb(50, 85, 160);
    padding: 0.8em 1.2em;
    border-radius: 10px;
    cursor: pointer;
    border-radius: 2em;
}
.sendMessageButton{
    color: white;
    width: 20px;
    height: 20px;
    margin-right: 0.5em;
    margin-left: 0.5em;
    background-color: rgb(50, 85, 160);
    padding: 0.5em 0.8em;
    border-radius: 10px;
    cursor: pointer;
}

.discussionContainer{
    position: absolute;
    padding-bottom: 1em;
    /* background-color: rgb(245, 245, 245); */
    width: 100%;
    top: 6.5em;
    bottom: 3.5em;
    overflow-y: auto;
    overflow-y: auto;
    overflow-x: hidden;
  }.discussionContainerMobile{
    position: absolute;
    padding-bottom: 1em;
    width: 100%;
    top: 7.73em;
    bottom: 7.25em;
    overflow-y: auto;
    overflow-x: hidden;
}

.interlocutorText, .interlocutorTextMobile{
  margin:0.5em;
  margin-left: 1em;
  padding: 0.5em;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
  width: fit-content;
  max-width: 65%;
  height: fit-content;
  font-size: 1em;
  font-family: 'Nunito';
  background-color: rgb(110, 110, 110);
  color: rgb(255, 255, 255);
}.interlocutorTextMobile{
  font-size: 1.2em;
}

.userText, .userTextMobile{
  margin:0.5em;
  margin-left: auto;
  margin-right: 1em;
  padding: 0.5em;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  width: fit-content;
  max-width: 65%;
  height: fit-content;
  font-size: 1em;
  font-family: 'Nunito';
  background-color: rgb(50, 85, 160);
  color: white;
}.userTextMobile{
  font-size: 1em;
}

.container{
  position: fixed;
  top:0em;
  left:0;
  width: 100%;
  height: 100%;
  /* height: calc(100% - 3em); */
  background-color: rgb(255, 255, 255);
  overflow: scroll;
  }  
  .lowContainerIndex{
    z-index: 998;
  }.highContainerIndex{
    z-index: 999;
  }

.containerAbsolute{
  position: absolute;
}
  
  .profilesLineDesktop {
    position: absolute;
    top: 6.45em;
    left: 0em;
    width: 100%;
    height: 1px;
    z-index: 9999;
    background-color: rgb(211,211,211);
  }
  
  .profilesLine {
    position: fixed;
    top: 7.65em;
    left: 0em;
    width: 100%;
    z-index: 9999;
    border: 1px solid rgb(211,211,211);
  }

  .slideRight {
    animation: slideRight 0.4s ease-out;
    animation-fill-mode: forwards;
    transform: translateX(100%);
  }
  
  .slideLeft {
    animation: slideLeft 0.4s ease-out;
    animation-fill-mode: forwards;
    transform: translateX(-100%);
  }
  
  @keyframes slideRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideLeft {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }

  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
  }