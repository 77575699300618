.container{
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9997;
    background-color: rgba(0, 0, 0, 0.3);
  }.discardChangesContainer{
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    padding: 1em;
    z-index: 9999;
    height: auto;
    width: 18em;
    border-radius: 12px;
    background-color: rgb(255, 255, 255);
  }.forgotEmptyFieldsMesssage{
    text-align: center;
    font-family: 'Nunito';
    font-size: 1.1em;
    font-weight: bold;
    color:rgb(44, 44, 44);
  }.emptyFieldsText{
    text-align: center;
    font-family: 'Nunito';
    font-size: 1.1em;
    color:rgb(185, 0, 0);
  }.exitCofirmationButtonsContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 2em;
  }.keepEditing{
    padding: 10px;
    font-size: 1em;
    width: 10em;
    height: fit-content;
    white-space: nowrap;
    font-family: 'Nunito';
    border: none;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
  }.keepEditing{
    color: rgb(245, 245, 245);
    background-color: rgb(50, 85, 160);
  }

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}