.container{
}

.desktopCropButton, .cropButton, .cancelCropButton{
    position: fixed;
    bottom: -3em;
    padding: 0.5em 0.7em;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.2em;
    width: 100%;
    max-width: 20em;
    border-radius: 50px;
    border: 0;
    cursor: pointer;
    font-weight: 400;
    font-family: 'Nunito';
    color: white;
    background-color: rgb(0, 0, 0);
    text-align: center;
}.cancelCropButton{
    bottom: -6em;
    background-color: rgb(176, 176, 176);
}.desktopCropButton{
    bottom: 0em;
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
  }