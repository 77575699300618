body {
    margin: 0;
    overscroll-behavior-y: none;
}

.desktopContainer{
    display: flex;
    justify-content: center;
    position: relative;
}

.mobileButtonsContainer{
    position: fixed;
    top:0.5em;
    left:0;
    z-index: 9996;
}

.utilityContainer{
    position: -webkit-sticky;
    position:sticky;
    top: 0;
    align-self: flex-start;
    z-index: 1;
}