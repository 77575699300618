.container{
    position: fixed;
    top: 3em;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 999;
}

.searchContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 38em;
    height: 3.2em;
    margin-bottom: 0.5em;
    padding: 0em;
    text-align: left;
    font-size: 1em;
    background-color: rgba(255, 255, 255, 1);
    /* border: 1px solid rgb(211, 211, 211); */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    outline: none;
    }.searchContainerMobile{
      margin-top: 0em;
      max-width: calc(100% - 5em);
      margin-left: 1em;
      /* margin-bottom: 4.5em; */
    }.searchContainerDesktop{
      margin-top: 1.6em;
      margin-bottom: 4.5em;
    }

.mobileSearch {
    text-align: left;
    font-size: 1em;
    color: #444444;
    font-family: 'Nunito';
    flex-grow: 1; /* Add this line to make the input stretch */
    margin: 0;
    padding: 0.7em;
    padding-left: 0.5em;
    width: 100%;
    border: none;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    outline: none;
  }

  .desktopSearch {
    text-align: left;
    font-size: 1em;
    color: #444444;
    font-family: 'Nunito';
    flex-grow: 1; /* Add this line to make the input stretch */
    margin: 0;
    padding: 0.7em;
    width: 100%;
    border: none;
    border-radius: 50px;
    outline: none;
  }

.slideTop {
animation: slideTop 0.3s ease-in-out;
animation-fill-mode: forwards;
transform: translateY(100%);
}

.slideBottom {
animation: slideBottom 0.3s ease-in-out;
animation-fill-mode: forwards;
transform: translateY(-100%);
}

@keyframes slideTop {
from {
    transform: translateY(100%);
}
to {
    transform: translateX(0);
}
}

@keyframes slideBottom {
from {
    transform: translateY(0);
}
to {
    transform: translateY(100%);
}
}

.clearButton {
  cursor: pointer;
  padding-left: 1em;
  padding-right: 1em;
  width: 15px;
  height: 15px;
  cursor: pointer;
  border-left: 1px solid rgb(211, 211, 211);
}

.desktopOptionsContainer{
  position: absolute;
  margin-left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 14em;
  overflow: auto;
  margin-top: -0.5em;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(211, 211, 211);
  border-radius: 4px;
  box-shadow: 0 px 6px rgba(0, 0, 0, 0.3);
  z-index: 9995;
}.mobileOptionsContainer{
  position: absolute;
  width: 100%-2em;
  margin-left: 1em;
  height: 20em;
  overflow: auto;
  margin-top: 0.5em;
  /* background-color: rgb(255, 255, 255); */
  /* border: 1px solid rgb(211, 211, 211); */
  border-radius: 4px;
  box-shadow: 0px 0px rgba(0, 0, 0, 0.3);
  z-index: 9995;
}

.option{
  margin: 0;
  padding-top:1em;
  padding-bottom:1em;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 0.9em;
  font-family: 'Nunito';
  font-weight: bold;
  color: #444444;
  cursor: pointer;
}

.searchButton{
  position: fixed;
  bottom: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: calc(100% - 2em);
  height: 2.7em;
  margin-left: 1em;
  font-size: 1.1em;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  color: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  background-color: rgb(50, 85, 160);
  z-index: 2;
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}