.title{
  margin-top: 2em;
  font-size: 1.2em;
  font-family: 'Nunito';
  font-weight: 500;
}

.container{
  position: fixed;
  top:0em;
  left:0;
  width: 100%;
  height: 100%;
  /* height: calc(100% - 3em); */
  background-color: rgb(255, 255, 255);
  overflow: scroll;
}.lowContainerIndex{
  z-index: 998;
}.highContainerIndex{
  z-index: 999;
}

.containerAbsolute{
  position: absolute;
}

.desktopContainer{

}

.mobileSavedTasksLayout{
    display: flex;
    flex-direction: column;
    margin: 0 1em;
    padding-top: 1em;
}

.desktopSavedTasksLayout{
    display: flex;
    flex-direction: column;
    margin-top: 1.6em;
}

.noSavedTasksMessage{
  text-align: center;
  text-align: center;
  color: rgb(180, 180, 180);
  font-size: 1.5em;
  font-family: 'Nunito';
  font-weight: 400;
}

.slideRight {
    animation: slideRight 0.4s ease-out;
    animation-fill-mode: forwards;
    transform: translateX(100%);
  }
  
  .slideLeft {
    animation: slideLeft 0.4s ease-out;
    animation-fill-mode: forwards;
    transform: translateX(-100%);
  }
  
  @keyframes slideRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideLeft {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }

  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
  }