.container{
  position: fixed;
  top:0em;
  left:0;
  /* padding-top:3em; */
  width: 100%;
  height: 100%;
  /* height: calc(100% - 3em); */
  background-color: rgb(255, 255, 255);
  overflow: scroll;
}.lowContainerIndex{
  z-index: 998;
}.highContainerIndex{
  z-index: 999;
}

.containerAbsolute{
  position: absolute;
}

.desktopContainer{
  margin-top: 2em;

}

.applicationContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 1px 6px rgb(0, 0, 0, 0.4);
  align-items: center;
  border-radius: 8px;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
  padding: 1em;
}

.listingTitle, .applicationButton, .componentTitle{
  margin: 0em;
  font-size: 1.2em;
  font-family: 'Nunito';
  font-weight: 500;
}.listingTitle{
  margin: auto;
  margin-left: 0;
  margin-right: 1.4em;
  align-items: center;
  font-size: 1.2em;
  font-family: 'Nunito';
  font-weight: 600;
  color:rgb(44, 44, 44);
  user-select: none;
}

.applicationButton{
  padding: 0.5em;
  background-color: rgb(50, 85, 160);
  color: white;
  border-radius: 6px;
  cursor: pointer;
}

.slideRight {
  animation: slideRight 0.4s ease-out;
  animation-fill-mode: forwards;
  transform: translateX(100%);
}

.slideLeft {
  animation: slideLeft 0.4s ease-out;
  animation-fill-mode: forwards;
  transform: translateX(-100%);
}

@keyframes slideRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.noAppliedTasksMessage{
  text-align: center;
  text-align: center;
  color: rgb(180, 180, 180);
  font-size: 1.5em;
  font-family: 'Nunito';
  font-weight: 400;
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}