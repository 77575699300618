.background{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 9998;
}.fadeIn{
  animation: fadeIn 0.3s ease-out;
  animation-fill-mode: forwards;
  background-color: rgba(0, 0, 0, 0.8);
}.fadeOut{
  animation: fadeOut 0.4s ease-out;
  animation-fill-mode: forwards;
  background-color: rgba(0, 0, 0, 0);
}

@keyframes fadeIn {
  from {
      background-color: rgba(0, 0, 0, 0);
}
to {
  background-color: rgba(0, 0, 0, 0.8);
}
}@keyframes fadeOut {
  from {
      background-color: rgba(0, 0, 0, 0.8);
}
to {
  background-color: rgba(0, 0, 0, 0);
}
}

.accountSettingsWindow{
    position: fixed;
    top: 0em;
    right: -100%;
    width: fit-content;
    height: 100vh;
    padding: 1.5em;
    padding-top:0.5em;
    background-color: rgb(255, 255, 255);
    user-select: none;
    cursor: pointer;
    z-index: 9999;
}
.slideRight{
    animation: slideRight 0.3s ease-in-out;
    animation-fill-mode: forwards;
    right: -100%;
  }.slideLeft{
    animation: slideLeft 0.4s ease-in-out;
    animation-fill-mode: forwards;
    right: 100%;
  }@keyframes slideRight {
    from {
        right: -100%; /* Start position, hidden below the screen */
  }
  to {
    right: 0; /* End position, slide up to the bottom of the screen */
  }
  }@keyframes slideLeft {
    from {
        right: 0%;
  }
  to {
    right: -100%;
  }
}

.hidden{
  display: none;
}

.userProfile{
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-top: 0;
  margin-bottom: 0.5em;
  margin-left: auto;
}
.userNameSurname{
    font-size: 1.3em;
    font-family: 'Nunito';
    font-weight: 650;
    margin:0;
    margin-bottom: 1em;
    color: rgb(44, 44, 44);
}

.buttonContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttonsText, .accountSettingsText, .tasksApplied{
    margin: 1em;
    padding-right: 2em;
    color: rgb(44,44,44);
    font-size: 1.27em;
    font-family: 'Nunito';
    font-weight: 750;
    cursor: pointer;
}.accountSettingsText{
  font-size: 1.05em;
  margin-top: 2em;
  margin-right:2em;
  margin-left: 0;
  font-weight: 650;
}.tasksApplied{
  margin: 0;
  margin-top: 1em;
  font-size: 1.1em;
  font-weight: 500;
}

.notRotatedArrow{
  transform: rotate(0);
  transition: transform 0.2s ease-in-out;

}.rotatedArrow{
  transform: rotate(90deg);
  transition: transform 0.2s ease-in-out;

}

.taskCategoriesCollapsed{
    display: flex;
    flex-direction: column;
    margin-left: 2em;
    max-height: 0em;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
  }.taskCategoriesExpanded{
    display: flex;
    flex-direction: column;
    margin-left: 2em;
    max-height: 10em;
    transition: max-height 0.2s ease-in-out;
    overflow: hidden;
}

.category{
    font-size: 1.1em;
    font-family: 'Nunito';
    color: rgb(44, 44, 44);
    font-weight: normal;
    margin: 0.3em;
    padding: 0.5em;
    user-select: none;
    cursor: pointer;
    font-weight: 500;
}

.createNewTask{
    margin-bottom:0;
    text-align: center;
    padding: 0.5em;
    color: white;
    font-family: 'Nunito';
    font-weight: normal;
    font-size: 1.1em;
    background-color: rgb(50,85,160);
    border-radius: 4px;
    cursor: pointer;
}

.icon{
    width: 25px;
    height: 25px;
}

.themeIcon{
  position: absolute;
  top: 1em;
  right: 1em;
  width: 25px;
  height: 25px;
  width: 25px;
  height: 25px;
  z-index: 9999;
}

.arrowIcon{
  width: 15px;
  height: 15px;
  margin-left: 0.5em;
}

.line{
  width: 100%;
  height: 1px;
  background-color: rgb(230, 230, 230);
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}