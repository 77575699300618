.background{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9997;
}.fadeIn{
  animation: fadeIn 0.3s ease-out;
  animation-fill-mode: forwards;
  background-color: rgba(0, 0, 0, 0.8);
}.fadeOut{
  animation: fadeOut 0.3s ease-out;
  animation-fill-mode: forwards;
  background-color: rgba(0, 0, 0, 0);
}

@keyframes fadeIn {
  from {
      background-color: rgba(0, 0, 0, 0);
}
to {
  background-color: rgba(0, 0, 0, 0.8);
}
}@keyframes fadeOut {
  from {
      background-color: rgba(0, 0, 0, 0.8);
}
to {
  background-color: rgba(0, 0, 0, 0);
}
}

.mobileContainer{
    position: fixed;
    z-index: 9997;
    bottom: 0;
    padding-top:0em;
    width: 100%;
    height: 78%;
    background-color: rgb(255, 255, 255);
    backdrop-filter: blur(15px);
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    overflow: auto;
}.slideUp{
  animation: slideUp 0.4s ease;
  animation-fill-mode: forwards;
  bottom: 0%;
}.slideDown{
  animation: slideDown 0.4s ease;
  animation-fill-mode: forwards;
  bottom: 100%;
}

@keyframes slideUp {
  from {
    bottom: -100%; /* Start position, hidden below the screen */
}
to {
    bottom: 0%; /* End position, slide up to the bottom of the screen */
}
}

@keyframes slideDown {
  from {
    bottom: 0%;
}
to {
  bottom: -100%;
}
}

.dragLine{
    position: absolute;
    top: 0.5em;
    left: 50%;
    margin-top: 8px;
    width: 40px;
    height: 6px;
    background-color: #ccc;
    border-radius: 6px;
    transform: translateX(-50%);
    border-radius: 50px;
    z-index: 9999;
}
.exitButton{
    margin-left: 1em;
    padding-top: 1em;
    width: 20px;
    height: 20px;
    color: white;
    cursor: pointer;
  }

/*----------Desktop----------*/

.createContainer{
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}
.desktopContainer{
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9997;
  padding: 1em;
  padding-top: 0;
  width: 70%;
  max-width: 50em;
  height: 35em;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: rgba(255, 255, 255);
  backdrop-filter: blur(15px);
  box-shadow: 10px solid black;
  border-radius: 6px;
}

.stopOverFlowing{
  overflow: hidden;
}