.signUpContainer{
    position: fixed;
    top: 0;
    left: 0;
    padding-left: 0em;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 9999;
}

.signUpText, .stepsText, .completionText{
  font-family: 'Nunito';
    margin-top: 1em;
    color: rgb(44, 44, 44);
    font-size: 1.6em;
    font-weight: 750;
}.stepsText{
    font-size: 1.2em;
    font-weight: 650;
    color: rgb(44, 44, 44);

}.guidanceText{
    font-size: 1.2em;
    font-family: 'Nunito';
    font-weight: 650;
    margin-right: auto;
    color: rgb(44, 44, 44);
}.completionText{
    margin-left: auto;
    margin-right: auto;
}

.secondSignupContainer{
    width: 90%;
    max-width: 30em;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}
.exitButton, .termsOfUseExitButton{
    width: 23px;
    height: 23px;
    margin-top: 2em;
    cursor: pointer;
}

.creationContainer{
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 30em;
    height: fit-content;
}

.inputContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.firstInputContainer, .secondInputContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 30em;
    height: fit-content;
    margin-top: 0em;
    margin-bottom: 0em;
    padding: 0em;
    text-align: left;
    font-size: 1em;
    background-color: rgb(245, 245, 245);
    border: 2px solid black;
    border-radius: 12px;
    outline: none;
}.selected{
    border: 2px solid rgb(50, 85, 160);
}.errorBorder{
    border: 2px solid rgb(181, 0, 0);
}

.nameInputfield, .surnameInputfield, .phoneNumberInputfield{
    width: 100%;
    font-size: 1em;
    background-color: rgba(255, 44, 44, 0);
    padding: 0.7em;
    border: 0;
    border-radius: 12px;
    outline: none;
}.phoneNumberInputfield{
    border-left: 2px solid black;
    border-radius: 0px;
}.countryCodeContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4em;
    height: 1.5em;
    border-right: 2px solid black;
    border-radius: 0px;
    cursor: pointer;
    font-family: 'Nunito';
  }.warningMessage{
    display: flex;
    flex-direction: row;
    margin-right: auto;
    align-items: left;
    text-align: left;
    user-select: none;
    cursor: default;
}.warningMessageText {
    margin-top:0.5em;
    margin-bottom:1em;
    color: rgb(208, 0, 0);
}.warningMessageTextDisabled{
    margin: 0;
    color: rgb(208, 0, 0, 0);
}

.passwordVisibleButton {
    margin-right: 0.5em;
    cursor: pointer;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.nextButton, .disabledNextButton{
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5em 0.7em;
    font-size: 1.2em;
    width: 100%;
    max-width: 25em;
    border-radius: 50px;
    border: 0;
    cursor: pointer;
    font-weight: bold;
    font-family: 'Nunito';
    color: white;
    background-color: rgb(50, 85, 160);
}.disabledNextButton{
    background-color: rgba(75, 75, 75, 0.229);
}

.appleSignInButton, .googleSignInButton{
    margin-top: 1em;
    padding: 0.5em 0.7em;
    font-size: 1.2em;
    width: 100%;
    max-width: 25em;
    border-radius: 50px;
    border: 0;
    cursor: pointer;
}.signInButton{
    font-weight: bold;
    font-family: 'Nunito';
    color: white;
    background-color: rgb(50, 85, 160);
}.googleSignInButton{
    margin-top: 0em;
}.appleSignInButton, .googleSignInButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 123, 255, 0);
    color: rgb(98, 98, 98);
    border: 1px solid black;
}.logo{
    width: 20px;
    height: 20px;
    margin-right: 0.5em;
}

.divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0;
  }
  
  .dividerLine {
    flex-grow: 1;
    height: 1px;
    background-color: rgb(204, 204, 204);
  }
  
  .dividerText {
    margin: 0 10px;
    color: #999;
    font-weight: bold;
  }

  .termsOfServiceWindow{
    position: relative;
    top: 0;
    left: 0em;
    /* padding: 1em; */
    height: 100em;
    background-color: rgb(158, 72, 72);
    z-index: 9999;
    overflow-y: auto;
  }

  .simpleTextDesktop, .simpleTextMobile{
    margin-top: -0.8em;
    margin-bottom: 1.5em;
    font-size: 1em;
    font-family: 'Nunito';
    font-weight: 300;
    color: rgb(100, 100, 100);
  }.simpleTextMobile{
    font-size: 0.9em;
  }

  .textTitle, .categoryTextTitle{
    font-family: 'Nunito';
    font-weight: 700;
    color: rgb(44, 44, 44);
  }.categoryTextTitle{
    font-size: 1.5em;
  }

  .termsOfServiceWindow{
    position: fixed;
    top: 0;
    left: 0em;
    padding: 1em;
    padding-bottom: 2em;
    width: 100%;
    height: 100vh;
    background-color: rgb(255, 255, 255);
    z-index: 9999;
    overflow-y: auto;
  }

  .termsOfServiceContainer{
    margin-left: auto;
    margin-right: auto;
  }

  .signInText, .termsOfServiceText{
    margin-top: 1.5em;
    text-align: center;
    font-size: 1em;
    font-family: 'Nunito';
    font-weight: bold;
    cursor: pointer;
  }.termsOfServiceText{
    text-align: left;
    color:rgb(44, 44, 44);
    font-weight: 400;
  }


  .hiddenInput{
    display: none;
  }
  
  .profilePictureContainer{
    margin-top: 2em;
    max-width: 30em;
  }
  .profilePicture{
    border: 2px solid rgb(100, 100, 100);
    border-radius: 50%;
    width: 150px;
    height: 150px;
    cursor: pointer;
  }.profilePictureNote, .errorImageOverSizeLimit{
    font-family: 'Nunito';
    font-size: 0.9em;
    font-weight: 600;
    margin: 0;
    margin-right: auto;
    color: rgb(44, 44, 44);
  }.errorImageOverSizeLimit{
    color: rgb(217, 23, 23);
  }

  .completionContainer{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .confirmationIMG{
    margin-left: auto;
    margin-right: auto;
    width: 50px;
    height: 50px;
  }

  .editButton{
    margin-top: 1em;
    font-size: 1em;
    border: 0;
    cursor: pointer;
    font-weight: 500;
    font-family: 'Nunito';
    font-weight: 600;
    color: rgb(44, 44, 44);
    background-color: rgba(0, 0, 0, 0);
  }

  .loadingIcon{
    margin-top: 1em; 
    margin-left: auto;
    margin-right: auto;
    width: 30px;
    height: 30px;
    animation: rotate 2s linear infinite; /* Adjust the duration and timing function as needed */
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}