.profileDetais{
    display: flex;
}

.profilePicture{
    width: 40px;
    height: 40px;
    margin-right:0.5em;
    border-radius: 50%;
}

.profileName{
    align-self: center;
    margin:0;
    font-size: 1em;
    font-weight: 600;
    color: rgb(44, 44, 44);
    font-family: 'Nunito';
}

.category{
    font-size: 0.9em;
    font-weight: 700;
    margin: 0em;
    padding:0;
    margin-left: 0.6em;
    font-family: 'Nunito';
    color:rgb(44, 44, 44);
}

.reviewDetails{
    display: flex;
    flex-wrap: wrap;
}

.star{
    width: 15px;
    height: 15px;
    margin: auto 0.05em;
}

.description{
    font-size: 1em;
    margin: auto;
    margin-top: 1em;
    font-family: 'Nunito';
    color:rgb(44, 44, 44);
}

.shortratings{
    margin:auto;
    display:flex;
    flex-wrap: wrap;
    font-family: 'Nunito';
    color: rgb(44, 44, 44)
}
.shortratings p:first-child{    
    margin-left: 0em;
}

.shortrating{
    margin-bottom: 0px;
    margin-right: 1em;
    align-self: center;
    text-align: center;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding: 5px 5px;
}

.datesubmited{
    font-size: 0.9em;
    align-self: center;
    text-align: center;
    font-family: 'Nunito';
    color:rgb(70, 70, 70);
    margin: 0em;
    margin-left: 1em;
}

/*----------DESKTOP----------*/

.desktopRatedTaskbodyContainer{
    position: relative;
    margin: 0.5em auto;
    padding: 1em;
    padding-top: 1em;
    background-color: white;
    border: 1px solid rgb(220, 220, 220);
    border-radius: 12px;
}

@media(max-width: 768px){
.desktopRatedTaskbodyContainer{
}
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}