.container, .containerDesktop{
  position: fixed;
  top:0em;
  left:0;
  padding-top:3em;
  width: 100%;
  height: calc(100% - 3em);
  background-color: rgb(255, 255, 255);
  overflow: scroll;
}
.containerAbsolute{
  position: absolute;
}

.lowContainerIndex{
  z-index: 998;
}.highContainerIndex{
  z-index: 999;
}
.profilePicture{
  margin-right: 0.5em;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.nameAndVerified{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1em;
  padding: 0.5em;
  border-radius: 8px;
  width: fit-content;
}.nameAndVerified:hover{
  background-color: rgb(220, 220, 220);
  cursor: pointer;
}
.userNameSurname{
  margin-top: 0em;
  margin-bottom: 0em;
  font-size: 1.3em;
  font-weight: 700;
    font-family: 'Nunito';
}.verified{
  margin-left: 0.5em;
  width: 20px;
  height: 20px;
}
.userEmail, .userPhoneNumber{
    margin-top: 0.5em;
    margin-left: 2em;
    font-size: 1em;
    font-family: 'Nunito';
    color: rgb(100, 100, 100);
}.userPhoneNumber{
  margin-top: -0.5em;
}

.grouppedCategoryMobile, .grouppedCategoryDesktop{
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  padding-left: 0.5em;
  width: fit-content;
}

.grouppedCategoryDesktop:hover{
  background-color: rgb(220, 220, 220);
}

.iconDesktop{
  width: 28px;
  height: 28px;
}.iconMobile{
  width: 22px;
  height: 22px;
}

.containerContentDesktop{
  position: fixed;
  top: 4em;
  bottom: 0;
  overflow: auto;
}.containerContentMobile{
  margin-top: 3em;
}

.elementsContainerDesktop, .elementsContainerMobile{
  width: 20em;
  height: fit-content;
  padding-left: 1em;
  /* border-right: 1px solid rgb(169, 169, 169); */
  overflow-y: scroll;
}.elementsContainerDesktop{
  margin:0.5em;
  padding-bottom: 1em;
  background-color: whitesmoke;
  border-radius: 14px;
  z-index: 9995;
}.elementsContainerMobile{
  position: relative;
  background-color: whitesmoke;
  padding-left: 0em;
  margin: 1em;
  padding-left: 0.5em;
  width: calc(100% - 2.5em);
  border-radius: 14px;
  overflow-y: scroll;
}

.settingsElementText, .titleElementText, .elementTextDesktop, .elementTextMobile, .signOutDesktop, .signOutMobile{
  margin: 0;
  padding-top: 0.85em;
  padding-bottom: 0.85em;
  padding-left: 0.7em;
  margin-right: 0.5em;
  font-size: 1.15em;
  font-weight: 500;
  font-family: 'Nunito';
  color: rgb(44, 44, 44);
  cursor: pointer;
}.elementTextMobile{
  font-size: 1em;
  font-weight: 650;
  width: fit-content;
  padding-left: 0.5em;
  cursor: auto;
}

.settingsElementText{
  padding-left: 0em;
  font-size: 1.9em;
  font-weight: 750;
  cursor: auto;

}
.titleElementText{
  padding-left: 0em;
  font-size: 1.15em;
  font-weight: 750;
  cursor: auto;
}

.signOutDesktop:hover{
  color: red;
  background-color: rgb(220, 220, 220);
  border-radius: 8px;
}.signOutMobile{
  color: red;
  cursor: auto;
  padding-left: 0.5em;
}

.line{
  width: 20;
  margin-top: 0.5em;
  margin-left: -1em;
  margin-bottom: 0.5em;
  height: 1px;
  background-color: rgb(210, 210, 210);
}

.slideRight {
    animation: slideRight 0.4s ease-out;
    animation-fill-mode: forwards;
    transform: translateX(100%);
  }
  
  .slideLeft {
    animation: slideLeft 0.4s ease-out;
    animation-fill-mode: forwards;
    transform: translateX(-100%);
  }
  
  @keyframes slideRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideLeft {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }

  .categoryTitle{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1em;
    font-family: 'Nunito';
    font-weight: 700;
    z-index: 9997;
  }

  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}