.mobileSignContainer, .desktopSignContainer{
    position: fixed;
    top: 0;
    left: 0;
    padding-left: 0em;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 9999;
}

.exitButton{
    width: 23px;
    height: 23px;
    margin-top: 2em;
    cursor: pointer;
}
.welcomeText, .signText{
    margin-top: 1em;
    color: rgb(44, 44, 44);
    font-size: 1.7em;
    font-weight: 800;
}.welcomeText{
    margin-top: 1em;
    text-align: right;
    font-size: 2em;
    font-family: 'Nunito';
}

.signInForm{
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    max-width: 30em;
}
.inputContainer, .googleAppleContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}.googleAppleContainer{
    width: 90%;
    max-width: 30em;
}
.emailContainer, .passwordContainer, .forgotPasswordInputContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 30em;
    height: fit-content;
    margin-top: 0em;
    padding: 0em;
    text-align: left;
    font-size: 1em;
    background-color: rgb(245, 245, 245);
    border: 2px solid black;
    border-radius: 12px;
    outline: none;
}.emailOrPhoneInputfield , .passwordInputfield, .forgotPasswordInputfield{
    width: 100%;
    font-size: 1em;
    background-color: rgba(255, 44, 44, 0);
    padding: 0.7em;
    border: 0;
    border-radius: 12px;
    outline: none;
}.selected{
    border: 2px solid rgb(50, 85, 160);
}.errorBorder{
    border: 2px solid rgb(181, 0, 0);
}

.warningMessage{
    display: flex;
    flex-direction: row;
    margin-right: auto;
    align-items: left;
    text-align: left;
    font-family: 'Nunito';
    user-select: none;
    cursor: default;
}.warningMessageText {
    margin-top:0.5em;
    margin-bottom:1em;
    color: rgb(208, 0, 0);
}.warningMessageTextDisabled{
    margin:0;
    color: rgb(208, 0, 0, 0);
}


.signInButton, .disabledSignedInButton, .appleSignInButton, .googleSignInButton{
    margin-top: 1em;
    padding: 0.5em 0.7em;
    font-size: 1.2em;
    width: 100%;
    max-width: 25em;
    border-radius: 50px;
    border: 0;
    cursor: pointer;
}.signInButton{
    font-weight: bold;
    font-family: 'Nunito';
    color: white;
    background-color: rgb(50, 85, 160);
}.disabledSignedInButton{
    font-weight: bold;
    font-family: 'Nunito';
    color: white;
    background-color: rgba(75, 75, 75, 0.229);
}.googleSignInButton{
    margin-top: 4em;
}.appleSignInButton, .googleSignInButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 123, 255, 0);
    color: rgb(98, 98, 98);
    border: 1px solid black;
}.passwordVisibleButton {
    margin-right: 0.5em;
    cursor: pointer;
    width: 20px;
    height: 20px;
    cursor: pointer;
}.forgotPassword{
    font-size: 1.05em;
    font-family: 'Nunito';
    text-align: left;
    color: rgb(44, 44, 44);
    margin: 0em;
    margin-top: 0.5em;
    margin-right: auto;
    padding: 0;
    cursor: pointer;
}.logo{
    width: 20px;
    height: 20px;
    margin-right: 0.5em;
}
.signUpMessage{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 1em;
    font-family: 'Nunito';
    font-weight: bold;
    color: rgb(0, 0, 0);
    cursor: pointer;
}

.divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0;
    width: 90%;
    max-width: 30em;
    margin-left: auto;
    margin-right: auto;
  }
  
  .dividerLine {
    flex-grow: 1;
    height: 1px;
    background-color: rgb(204, 204, 204);
  }
  
  .dividerText {
    margin: 0 10px;
    color: #999;
    font-weight: bold;
  }
  
.forgotPasswordContainer{
    display:flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    width: 90%;
    height: fit-content;
    top: 40%;
    left: 50%;
    width: 90%;
    transform: translate(-50%,-50%);
    background-color: rgb(255, 255, 255);
}.resetPasswordText{
    color: rgb(44,44,44);
    font-size: 2em;
    font-family: 'Nunito';
    font-weight: bold;
}

.forgotPasswordCancel, .forgotPasswordSendEmailButton, .disabledForgotPasswordButton{
    margin: 1em;
    padding: 1em;
    font-size: 1.1em;
    font-family: 'Nunito';
    color: rgb(44,44,44);
    background-color: rgb(255, 255, 255);
    border: none;
    border-radius: 8px;
    cursor: pointer;
}.disabledForgotPasswordButton{
    font-family: 'Nunito';
    color: rgb(148, 148, 148);
    background-color: rgba(255, 255, 255, 0);
    cursor: auto;
}
.passwordEmailSentContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 40%;
    left: 50%;
    width: 90%;
    transform: translate(-50%,-50%);
}
.passwordSentText{
    font-size: 1.3em;
    font-family: 'Nunito';
    font-weight: bold;
}
.forgotPasswordSendEmailButton{
    color: white;
    background-color: black;
}

.foregroundBlock{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}