.background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9997;
}.fadeIn{
    animation: fadeIn 0.3s ease-out;
    animation-fill-mode: forwards;
    background-color: rgba(0, 0, 0, 0.8);
  }.fadeOut{
    animation: fadeOut 0.3s ease-out;
    animation-fill-mode: forwards;
    background-color: rgba(0, 0, 0, 0);
  }
  
  @keyframes fadeIn {
    from {
        background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.8);
  }
  }@keyframes fadeOut {
    from {
        background-color: rgba(0, 0, 0, 0.8);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
  }
  }

.reviewForm{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 90%;
    max-width: 35em;
    height: 50vh;
    padding: 1em;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    z-index: 9999;
}

.processTextContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 1em;
    border-bottom: 1px solid rgb(200, 200, 200);
}.processTextUnselected{
    color: rgb(200, 200, 200);
}
.processTextSelected{
    color: rgb(50,85,160);
    font-weight: bold;
    border-bottom: 2px solid rgb(50,85,160);
}
.previousButton{
    position: absolute;
    left: 1em;
    bottom: 1em;
    padding: 0.5em 2em;
    border: 1px solid grey;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0);
    color: Grey;
    font-family: 'Nunito';
    font-size: 1em;
    cursor: pointer;
    user-select: none;
}
.nextButton{
    position: absolute;
    right: 1em;
    bottom: 1em;
    padding: 0.5em 2em;
    background-color: rgb(50,85,160);
    color: rgb(255, 255, 255);
    font-family: 'Nunito';
    font-size: 1em;
    cursor: pointer;
    border: 1px solid rgb(50,85,160);
    border-radius: 50px;
    user-select: none;
}.disabledNextButton{
    position: absolute;
    right: 1em;
    bottom: 1em;
    padding: 0.5em 2em;
    color: rgb(255, 255, 255);
    font-family: 'Nunito';
    font-size: 1em;
    cursor: pointer;
    background-color: rgba(128, 128, 128, 0.6);
    border: 1px solid rgba(175, 175, 175, 0.6);
    border-radius: 50px;
    user-select: none;
}.submitReviewButton{
    color: white;
    background-color: rgba(217, 124, 48);
    border: 1px solid rgba(217, 124, 48);
    border-radius: 50px;
}

.questionsCounter{
    position: absolute;
    margin-left: 1em;
    font-size: 1em;
    font-family: 'Nunito';
    font-weight: 400;
    color: rgb(44,44,44);
}

.mobileQuestionsCounter{
    margin-bottom: 2em;
    text-align: center;
    font-size: 1.1em;
    font-family: 'Nunito';
    color: grey;
}

.questionTitle{
    text-align: center;
    font-family: 'Nunito';
    font-weight: bold;
    font-size: 1.05em;
}
.choicesContainer{
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
}

.choiceContainerUnselected{
    margin: 0.5em;
    border: 1px solid rgb(210, 210, 210);
    border-radius: 4px;
    padding: 0 1em;
    cursor: pointer;
    user-select: none;
    background-color: whitesmoke;
}.choiceContainerSelected{
    margin: 0.5em;
    border: 2px solid rgb(50,85,160);
    border-radius: 4px;
    padding: 0 1em;
    cursor: pointer;
    user-select: none;
    background-color: whitesmoke;
}

.questionAnswerUnselected{
    text-align: center;
    font-size: 1em;
    font-family: 'Nunito';
    color: grey;
}.questionAnswerSelected{
    text-align: center;
    color: rgb(50,85,160);
    font-size: 1em;
    font-family: 'Nunito';
    font-weight: bold;
}

.starsContainer{
    display: flex;
    flex-direction: column;
}.starsText{
    text-align: center;
    font-size:1.2em;
    font-family: 'Nunito';
}

.commentInput{
    margin-left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 15em;
    font-size: 1em;
    font-family: 'Nunito';
    color: rgb(44, 44, 44);
    background-color: whitesmoke;
    border-radius: 4px;
    border: 1px solid rgb(200, 200, 200);
    outline: 0;
}

.mobileContainer{
    position: fixed;
    z-index: 9997;
    bottom: 0;
    left: 50%;
    padding-top:0em;
    transform: translateX(-50%);
    width: 100%;
    height: 78%;
    background-color: rgb(255, 255, 255);
    backdrop-filter: blur(15px);
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow: auto;
}.slideUp{
  animation: slideUp 0.3s ease;
  animation-fill-mode: forwards;
  bottom: -78%;
}.slideDown{
  animation: slideDown 0.3s ease;
  animation-fill-mode: forwards;
  bottom: 78%;
}

@keyframes slideUp {
  from {
    bottom: -78%; /* Start position, hidden below the screen */
}
to {
    bottom: 0; /* End position, slide up to the bottom of the screen */
}
}

@keyframes slideDown {
  from {
    bottom: 0%;
}
to {
    bottom: -78%;
}
}

.disabled{
    display: none;
}

.cancelReviewContainer{
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cancelButton, .continueButton{
    margin: 0;
    font-size: 1.1em;
    font-family: 'Nunito';
    text-align: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 50%;
    transform: translate(-50%);
}.cancelButton{
    margin-top: 2em;
    color: rgb(100, 100, 100);
    background-color: rgb(0, 0, 0,0);
}.continueButton{
    width: 10em;
    padding: 1em 1em;
    color: rgb(245, 245, 245);
    background-color: rgb(50,85,160);
}


.mobileCancelReviewContainer{
    position: absolute;
    display: flex;
    width: 100%;
    flex-direction: column;
    bottom: 3em;
    left: 50%;
    transform: translate(-50%);
}

.mobileCancelButton, .mobileContinueButton{
    margin: 0;
    font-size: 1.1em;
    padding: 1em 1em;
    font-family: 'Nunito';
    text-align: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 50%;
    transform: translateX(-50%);
}.mobileCancelButton{
    width: fit-content;
    padding: 0;
    margin-top: 0.5em;
    color: rgb(100, 100, 100);
    background-color: rgb(0, 0, 0,0);
}.mobileContinueButton{
    width: 80%;
    margin-bottom: 1em;
    color: rgb(245, 245, 245);
    background-color: rgb(50, 80, 160);
}
.cancelMessage{
    font-size: 1.2em;
    font-family: 'Nunito';
    color: rgb(44, 44, 44);
    text-align: center;
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
    src: url('../../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}