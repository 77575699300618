.background{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9997;
}.fadeIn{
  animation: fadeIn 0.3s ease-out;
  animation-fill-mode: forwards;
}.fadeOut{
  animation: fadeOut 0.3s ease-out;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
      background-color: rgba(0, 0, 0, 0);
}
to {
  background-color: rgba(0, 0, 0, 0.8);
}
}@keyframes fadeOut {
  from {
      background-color: rgba(0, 0, 0, 0.8);
}
to {
  background-color: rgba(0, 0, 0, 0);
}
}

.candidatesContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    z-index: 9997;
    width: fit-content;
    height: 25em;
    right: 0em;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    /* mask-image: linear-gradient(to bottom, transparent 0%, black 10%, black 90%, transparent 100%); */
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
  }.candidatesContainer::-webkit-scrollbar {
    background-color: transparent;
  }.candidatesContainer::-webkit-scrollbar-thumb {
    background-color: transparent;
  }.candidatesContainer::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .mobileContainer{
    position: fixed;
    z-index: 9997;
    bottom: 0;
    left: 50%;
    padding-top:0em;
    transform: translateX(-50%);
    width: 100%;
    height: 78%;
    background-color: rgb(255, 255, 255);
    backdrop-filter: blur(15px);
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    overflow: auto;
}.slideUp{
  animation: slideUp 0.3s ease;
  animation-fill-mode: forwards;
  bottom: -78%;
}.slideDown{
  animation: slideDown 0.3s ease;
  animation-fill-mode: forwards;
  bottom: 78%;
}

@keyframes slideUp {
  from {
    bottom: -78%; /* Start position, hidden below the screen */
}
to {
    bottom: 0; /* End position, slide up to the bottom of the screen */
}
}

@keyframes slideDown {
  from {
    bottom: 0%;
}
to {
    bottom: -78%;
}
}
.candidateDetails{
  display: flex;
  flex-direction: row;
}
.candidateContainer{
  display: flex;
  min-width: 20em;
  padding: 1em;
  padding-bottom: 0em;
}.candidateName{
  margin-bottom: 0em;
  color: rgb(44, 44, 44);
  font-size: 1em;
  font-family: 'Nunito';
  font-weight: 650;
}.candidateProfile{
  width: 50px;
  height: 50px;
  margin-right: 0.5em;
  border-radius: 50px;
}.candidateRating img {
  width: 15px;
  height: 15px;
  margin-left: 0.05em;
}.viewProfileButton{
  padding: 0.8em 1.5em;
  color: white;
  font-size: 1em;
  font-family: 'Nunito';
  font-weight: 600;
  background-color: rgb(50, 85, 160);
  border-radius: 8px;
  position: absolute;
  right: 1em;
  cursor: pointer;
}
.noCandidates, .noCandidatesDesktop{
  position: absolute;
  top: 5em;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Nunito';
  font-weight: 600;
  color: rgb(215, 215, 215);
  z-index: 9999;
  white-space: nowrap;
}.noCandidatesDesktop{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%, -50%);
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}