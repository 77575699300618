.logoContainerDesktop, .logoContainerMobile{
    display: flex;
    align-items: center;
    margin-top: -1em;
    margin-bottom: 2em;
    cursor: pointer;
    width: fit-content;
}.logoContainerMobile{
    margin-top: -4em;
}

.logo{
  margin-top: 1em;
  width: 110px;
  height: 60px;
}

.logoName{
  font-family: 'Nunito';
  font-weight: 700;
    font-size: 1.2em;
    color: rgb(44, 44, 44);
}

.contentContainerDesktop, .contentContainerMobile{
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    margin-left: auto;
    margin-right: auto;
    max-width: 78.5em;
  }
.textTitle, .categoryTextTitle{
  font-family: 'Nunito';
  font-weight: 700;
    color: rgb(44, 44, 44);
  }.categoryTextTitle{
    font-size: 1.5em;
    margin-top: -0.5em;
  }.simpleTextDesktop, .simpleTextMobile{
    margin-top: -0.8em;
    margin-bottom: 1.5em;
    font-size: 1em;
    font-family: 'Nunito';
    font-weight: 300;
    color: rgb(100, 100, 100);
  }.simpleTextMobile{
    font-size: 0.9em;
  }

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 1 1000; /* 1 is the minimum weight, 1000 is the maximum weight */
  src: url('../../assets/fonts//Nunito-VariableFont_wght.ttf') format('truetype');
}